<template>
  <div class="base-component width-contained">
    <div class="search-block-row">
      <h2>{{ title }}</h2>
      <div class="search-block-form">
        <div class="search-block-input" autocomplete="off">
          <input
            type="text"
            placeholder="Search"
            v-model="searchTerm"
            v-on:keyup.enter="searchCatalogue"
            :class="{ searchTermMissing: 'error' }"
          />
          <button
            class="search-block-input-button"
            aria-label="Search"
            @click="searchWebsite"
          ></button>
        </div>
        <div class="search-block-buttons">
          <button class="btn btn-primary-alt" @click="searchCatalogue">
            <span class="btn-container">
              <span class="btn-icon las la-book-open"></span>
              <span class="btn-text">Search the Catalogue</span>
            </span>
          </button>
          <button class="btn btn-primary-alt" @click="searchWebsite">
            <span class="btn-container">
              <span class="btn-icon las la-desktop"></span>
              <span class="btn-text">Search the Website</span>
            </span>
          </button>
          <button class="btn btn-primary-alt" @click="searchEvents">
            <span class="btn-container">
              <span class="btn-icon las la-calendar"></span>
              <span class="btn-text">Search Events</span>
            </span>
          </button>
        </div>
      </div>

      <a class="search-block-link" :href="advancedSearchPageUrl" target="_blank">Advanced Search</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    searchWebsiteUrl: {
      type: String,
      required: true,
    },
    searchCatalogueUrl: {
      type: String,
      required: true,
    },
    searchEventsUrl: {
      type: String,
      required: true,
    },
    advancedSearchPageUrl: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      searchTerm: null,
      searchTermMissing: false
    };
  },
  methods: {
    searchWebsite() {
      if (!this.searchTerm || this.searchTerm.length <= 0) {
        this.searchTermMissing = true;
        return;
      }
      
      this.searchTermMissing = false;
      
      let url = this.searchWebsiteUrl + "?t=" + this.searchTerm;
      this.$_redirectToUrl(url, false);
    },
    searchCatalogue() {
      let self = this;
      let term = self.searchTerm ? self.searchTerm.replace(" ", "+") : "";
      let url = `${self.searchCatalogueUrl}?ENTRY=${term}&ENTRY_NAME=BS&ENTRY_TYPE=K&SORTS=SQL_REL_BIB&GQ=${term}&ISGLB=1&NRECS=20`;
      self.$_redirectToUrl(url, true);
    },
    searchEvents() {
      let self = this;
      let term = self.searchTerm ? self.searchTerm.replace(" ", "+") : "";
      let url =
        `${self.searchEventsUrl}?keywords=${term}`;
      self.$_redirectToUrl(url, false);
    },
    $_redirectToUrl(searchUrl, newTab) {
      if (newTab) {
        window.open(searchUrl, "_blank");
        return;
      }

      window.location.href = searchUrl;
    }
  },
};
</script>