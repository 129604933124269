<script>
import axios from "axios";
export default {
  install(Vue) {
    Vue.submitSignUpForm = function(
      url,
      data,
      successCallback,
      errorCallback,
      finallyCallback
    ) {
      if (successCallback) {
        if (errorCallback) {
          if (finallyCallback) {
            axios
              .post(url, data)
              .then(successCallback)
              .catch(errorCallback)
              .finally(finallyCallback);
          } else {
            axios
              .post(url, data)
              .then(successCallback)
              .catch(errorCallback);
          }
        } else {
          if (finallyCallback) {
            axios
              .post(url, data)
              .then(successCallback)
              .finally(finallyCallback);
          } else {
            axios
              .post(url, data)
              .then(successCallback);
          }
        }
      } else if (errorCallback) {
        if (finallyCallback) {
          axios
            .post(url, data)
            .catch(errorCallback)
            .finally(finallyCallback);
        } else {
          axios.post(url, data).catch(errorCallback);
        }
      } else {
        if (finallyCallback) {
          axios.post(url, data).finally(finallyCallback);
        } else {
          axios.post(url, data);
        }
      }
    };
  }
};
</script>