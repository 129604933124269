<template>
	<div class="locations-modal-locations-list" ref="locationsContainer"></div>
</template>

<script>
import Vue from 'vue';
import Location from '@/components/location-modal/Location.vue';

export default {
	data() {
		return {
			hidden: true,
			locations: [],
		};
	},
	methods: {
		init(locations) {
			let self = this;
			let container = self.$refs.locationsContainer;
			let locationClass = Vue.extend(Location);

			for (let i = 0; i < locations.length; i++) {
				let location = locations[i];

				let locationComponent = new locationClass({
					propsData: {
						location: location,
					},
				});

				locationComponent.$on('locationSelected', self.locationSelected);

				locationComponent.$mount();
				container.appendChild(locationComponent.$el);
			}
		},
		locationSelected(location) {
			let self = this;
			self.$emit('locationSelected', location);
		},
	},
};
</script>
