<script>
	export default {
		install(Vue) {
			Vue.getFilteredPageOfBlogArticles = function (
				url,
				startDate,
				endDate,
				tags,
				ageGroups,
				keywords,
				pageNumber,
				blogPageId,
				pageSize,
				successCallback,
				errorCallback,
				finallyCallback
			) {
				let fullUrl = `${url}?pageNumber=${pageNumber}&pageSize=${pageSize}&nodeId=${blogPageId}`;

				if (startDate !== null) {
					let startDateString = `${startDate.year}-${startDate.month}-${startDate.day}`;
					fullUrl += `&startDate=${startDateString}`;
				}

				if (endDate !== null) {
					let endDateString = `${endDate.year}-${endDate.month}-${endDate.day}`;
					fullUrl += `&endDate=${endDateString}`;
				}

				if (tags && tags.length > 0) {
					fullUrl += `&categories=${tags.join("+")}`;
				}

				if (ageGroups && ageGroups.length > 0) {
					fullUrl += `&ageGroups=${ageGroups.join("+")}`;
				}

				if (keywords && keywords.length > 0) {
					fullUrl += `&keywords=${keywords.join("+")}`;
				}

				Vue.getDataFromUrl(
					fullUrl,
					successCallback,
					errorCallback,
					finallyCallback
				);

			};

			Vue.getFeaturedArticle = function (
				url,
				articleId,
				successCallback,
				errorCallback,
				finallyCallback
			) {
				let fullUrl = `${url}?id=${articleId}`;

				Vue.getDataFromUrl(
					fullUrl,
					successCallback,
					errorCallback,
					finallyCallback
				);

			};

		}
	};
</script>