<template>
	<div class="tingle-modal tingle-search-container colour-way cw-db-o tingle-modal--visible">
		<div class="search-modal-wrapper">
			<button type="button" class="tingle-modal__close" @click="closeModal">
				<span class="tingle-modal__closeIcon colour-primary"></span>
				<span class="tingle-modal__closeLabel">Close</span>
			</button>
			<div class="tingle-modal-box">
				<div class="tingle-modal-box__content">
					<div class="search-modal">
						<div class="search-modal-row">
							<h1 class="search-modal-title colour-primary">Search</h1>
							<div class="search-modal-form">
								<div class="search-modal-input" autocomplete="off">
									<input
										type="text"
										placeholder="Search"
										v-model="searchTerm"
										v-on:keyup.enter="searchCatalogue"
										:class="{ searchTermMissing: 'error' }"
									/>
									<button
										class="search-modal-input-button colour-secondary"
										aria-label="Search"
										@click="searchWebsite"
									></button>
								</div>
								<div class="search-modal-buttons">
									<button class="btn btn-primary" @click="searchCatalogue">
										<span class="btn-container">
											<span class="btn-icon las la-book-open"></span>
											<span class="btn-text">Search the Catalogue</span>
										</span>
									</button>
									<button class="btn btn-primary" @click="searchWebsite">
										<span class="btn-container">
											<span class="btn-icon las la-desktop"></span>
											<span class="btn-text">Search the Website</span>
										</span>
									</button>
									<button class="btn btn-primary" @click="searchEvents">
										<span class="btn-container">
											<span class="btn-icon las la-calendar"></span>
											<span class="btn-text">Search Events</span>
										</span>
									</button>
								</div>
							</div>
							<a :href="advancedSearchPageUrl" target="_blank" class="btn-link-alt colour-primary search-modal-link">
								<span class="btn-container">Advanced Search</span>
							</a>
						</div>
					</div>
				</div>
				<div class="tingle-modal-box__footer">
					<div class="search-modal-dots">
						<div class="search-modal-dots-container">
							<svg viewBox="0 0 1920 160" xmlns="http://www.w3.org/2000/svg">
								<defs>
									<pattern id="searchModalDots" x="0" y="0" width="61" height="30" patternUnits="userSpaceOnUse">
										<circle cx="6" cy="6" r="6.12293" class="fill-secondary"></circle>
										<circle cx="37" cy="23" r="6.12293" class="fill-secondary"></circle>
									</pattern>
								</defs>
								<rect fill="url(#searchModalDots)" width="100%" height="160"></rect>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	// props: {
	// 	searchWebsiteUrl: {
	// 		type: String,
	// 		required: true,
	// 	},
	// 	searchCatalogueUrl: {
	// 		type: String,
	// 		required: true,
	// 	},
	// 	searchEventsUrl: {
	// 		type: String,
	// 		required: true,
	// 	},
	// 	advancedSearchPageUrl: {
	// 		type: String,
	// 		required: true,
	// 	},
	// },
	data() {
		return {
			searchTerm: null,
			searchTermMissing: false,

			searchWebsiteUrl: null,
			searchCatalogueUrl: null,
			searchEventsUrl: null,
			advancedSearchPageUrl: null,
		};
	},
	methods: {
		init(properties) {
			var self = this;

			self.searchWebsiteUrl = properties.searchWebsiteUrl;
			self.searchCatalogueUrl = properties.searchCatalogueUrl;
			self.searchEventsUrl = properties.searchEventsUrl;
			self.advancedSearchPageUrl = properties.advancedSearchPageUrl;
		},
		searchWebsite() {
			if (!this.searchTerm || this.searchTerm.length <= 0) {
				this.searchTermMissing = true;
				return;
			}

			this.searchTermMissing = false;

			let url = this.searchWebsiteUrl + '?t=' + this.searchTerm;
			this.$_redirectToUrl(url, false);
		},
		searchCatalogue() {
			let self = this;
			let term = self.searchTerm ? self.searchTerm.replace(' ', '+') : '';
			let url = `${self.searchCatalogueUrl}?ENTRY=${term}&ENTRY_NAME=BS&ENTRY_TYPE=K&SORTS=SQL_REL_BIB&GQ=${term}&ISGLB=1&NRECS=20`;
			self.$_redirectToUrl(url, true);
		},
		searchEvents() {
			let self = this;
			let term = self.searchTerm ? self.searchTerm.replace(' ', '+') : '';
			let url = `${self.searchEventsUrl}?keywords=${term}`;
			self.$_redirectToUrl(url, false);
		},
		$_redirectToUrl(searchUrl, newTab) {
			if (newTab) {
				window.open(searchUrl, '_blank');
				return;
			}

			window.location.href = searchUrl;
		},
		closeModal() {
			this.$emit('closeModal');
		},
	},
};
</script>
