<template>
	<div id="AddressDetails" ref="AddressDetails" :class="{ 'd-none': IsHidden }">
		<div class="form">
			<h2>Residential Address</h2>
			<ErrorPanel ref="errorPanel" />
			<div class="form-wrapper">
				<div class="form-row">
					<div class="form-full-col form-col">
						<div v-show="!ManualAddressMode">
							<button class="btn btn-primary btn-small" @click="toggleAddressMode()">
								<i class="las la-toggle-off"></i>
								&nbsp;Enter your address manually
							</button>
						</div>
						<div v-show="!ManualAddressMode">
							<div
								class="field-wrapper"
								:class="{ 'input-error': AddressAutocompleteHasErrors || AddressAutocompleteHasFatalError }"
							>
								<FieldLabel LabelText="Address" :IsRequired="true" />
								<autocomplete
									ref="addressAutocomplete"
									:source="AddressAutocompleteDataSource"
									results-value="FormattedAddress"
									results-property="Id"
									results-display="FormattedAddress"
									placeholder="Enter your address"
									input-class="address-autocomplete"
									@selected="addressSelected"
									@clear="resetAddress"
								/>
								<span class="field-validation" v-show="AddressAutocompleteHasFatalError">
									<p>There is an issue with the address look up.</p>
									<button class="btn btn-primary btn-small" @click="toggleAddressMode()">
										<i class="las la-toggle-off"></i>
										&nbsp;Enter your address manually
									</button>
								</span>
								<span class="field-validation" v-show="AddressAutocompleteHasErrors">
									Please enter your address
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-show="ManualAddressMode">
				<div class="form-wrapper">
					<div class="form-row">
						<div class="form-full-col form-col">
							<div v-show="ManualAddressMode">
								<button class="btn btn-primary btn-small" @click="toggleAddressMode()">
									<i class="las la-toggle-on"></i>
									&nbsp;Return to address finder
								</button>
							</div>
							<TextBox
								ref="streetFormField"
								LabelText="Street address"
								Type="text"
								Name="Street"
								PlaceholderText="Street address"
								:IsRequired="true"
								RequiredMessage=""
								ValidationType=""
								ValidationMessage=""
								CustomValidation=""
								AdditionalCssClasses=""
							/>
							<TextBox
								ref="suburbFormField"
								LabelText="Suburb"
								Type="text"
								Name="Suburb"
								PlaceholderText="Suburb"
								:IsRequired="true"
								RequiredMessage=""
								ValidationType=""
								ValidationMessage=""
								CustomValidation=""
								AdditionalCssClasses=""
							/>
							<Dropdown
								ref="stateFormField"
								LabelText="State"
								Name="State"
								PlaceholderText="Please select"
								:Options="[
									{
										Text: 'ACT',
										Value: 'ACT',
										Selected: false,
									},
									{
										Text: 'NSW',
										Value: 'NSW',
										Selected: false,
									},
									{
										Text: 'NT',
										Value: 'NT',
										Selected: false,
									},
									{
										Text: 'QLD',
										Value: 'QLD',
										Selected: false,
									},
									{
										Text: 'SA',
										Value: 'SA',
										Selected: false,
									},
									{
										Text: 'TAS',
										Value: 'TAS',
										Selected: false,
									},
									{
										Text: 'VIC',
										Value: 'VIC',
										Selected: false,
									},
									{
										Text: 'WA',
										Value: 'WA',
										Selected: false,
									},
								]"
								:IsRequired="true"
							/>
							<TextBox
								ref="postcodeFormField"
								LabelText="Postcode"
								Type="text"
								Name="Postcode"
								PlaceholderText="Postcode"
								:IsRequired="true"
								RequiredMessage=""
								ValidationType=""
								ValidationMessage=""
								CustomValidation=""
								AdditionalCssClasses=""
								@onValid="setResidentialArea"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="form-wrapper">
				<div class="form-row">
					<div class="form-full-col form-col">
						<Dropdown
							ref="residentialAreaFormField"
							LabelText="Residential Area"
							Name="ResidentialArea"
							PlaceholderText="Please select"
							:Options="ResidentialAreas"
							:IsRequired="true"
						/>
						<Dropdown
							ref="localLibraryFormField"
							LabelText="Local Library"
							Name="ResidentialArea"
							PlaceholderText="Please select"
							:Options="Libraries"
							:IsRequired="true"
						/>
						<Checkbox
							ref="termsAndConditionsFormField"
							Name="TermsAndConditions"
							:IsCheckedByDefault="true"
							:IsRequired="true"
						>
							<span slot="labelText">
								I accept YPRL's terms and
								<a href="/about-the-library/policies-plans-strategic-frameworks/" target="_blank">
									conditions of membership
								</a>
								and agree with my details being shared with all institutions in the network (details will not be
								disclosed to organisations outside the library network without your consent)
							</span>
						</Checkbox>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-col form-navigation-left">
					<button type="submit" class="btn btn-primary btn-back back-secondary" :disabled="Submitting" @click="back()">
						<span class="las la-chevron-circle-left"></span>
						Previous
					</button>
				</div>
				<div class="form-col form-navigation-right">
					<button type="submit" class="btn btn-primary back-secondary" :disabled="Submitting" @click="submit()">
						<span v-if="!Submitting">
							Submit
							<span class="las la-sign-in-alt"></span>
						</span>

						<span v-if="Submitting" class="btn-container">
							<span class="btn-text">Submitting</span>
							<span class="btn-icon btn-icon-right las la-spinner la-spin"></span>
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Vue from 'vue';
import librariesJson from '@/assets/library-signup-form-libraries.json';
import residentialAreasJson from '@/assets/library-signup-form-residential-areas.json';
import residentialAreaPostcodeMatchesJson from '@/assets/library-signup-form-residential-area-postcode-matches.json';
import ErrorPanel from '@/components/library-sign-up-form/ErrorPanel.vue';
import Autocomplete from 'vuejs-auto-complete';
import Checkbox from '@/components/form-fields/Checkbox.vue';
import Dropdown from '@/components/form-fields/Dropdown.vue';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';
import TextBox from '@/components/form-fields/TextBox.vue';

export default {
	name: 'Step05AddressDetails',
	components: {
		ErrorPanel,
		Autocomplete,
		Checkbox,
		Dropdown,
		FieldLabel,
		TextBox,
	},
	data() {
		return {
			IsHidden: true,
			HasErrors: false,
			Errors: [],
			AddressAutocompleteHasErrors: false,
			AddressAutocompleteHasFatalError: false,
			Submitting: false,

			Libraries: librariesJson,
			ResidentialAreas: residentialAreasJson,

			AddressAutocompleteDataSource: Vue.addressAutocompleteDataSource,
			ManualAddressMode: false,

			Street: null,
			Suburb: null,
			State: null,
			Postcode: null,

			ResidentialAreaPostcodeMatches: residentialAreaPostcodeMatchesJson,
			ResidentialArea: null,
			LocalLibrary: null,

			TermsAndConditionsAccepted: false,
		};
	},
	methods: {
		toggleAddressMode() {
			var self = this;
			self.ManualAddressMode = !self.ManualAddressMode;
			self.resetAddress();
		},
		addressSelected(address) {
			var self = this;

			self.Submitting = true;
			self.AddressAutocompleteHasErrors = false;
			self.AddressAutocompleteHasFatalError = false;

			Vue.getAddress(
				address.selectedObject.Id,
				function(response) {
					self.Submitting = false;
					if (response.status === 200) {
						self.AddressAutocompleteHasErrors = false;

						self.Street = response.data.StreetAddress;
						self.$refs.streetFormField.Value = self.Street;

						self.Suburb = response.data.Suburb;
						self.$refs.suburbFormField.Value = self.Suburb;

						self.State = response.data.State;
						self.$refs.stateFormField.setValue(self.State);

						self.Postcode = response.data.Postcode;
						self.$refs.postcodeFormField.Value = self.Postcode;

						//validating the postcode field will run the 'setResidentialArea()' function
						self.$refs.postcodeFormField.clearErrors();
						self.$refs.postcodeFormField.validate();
					} else {
						self.AddressAutocompleteHasFatalError = true;
					}
				},
				function() {
					self.AddressAutocompleteHasFatalError = true;
				}
			);
		},
		resetAddress() {
			var self = this;

			self.$refs.addressAutocomplete.value = '';
			self.AddressAutocompleteHasErrors = false;
			self.AddressAutocompleteHasFatalError = false;
			self.Street = null;
			self.Suburb = null;
			self.State = null;
			self.Postcode = null;

			var addressFormFields = [
				self.$refs.streetFormField,
				self.$refs.suburbFormField,
				self.$refs.stateFormField,
				self.$refs.postcodeFormField,
				self.$refs.residentialAreaFormField,
			];

			for (var i = 0; i < addressFormFields.length; i++) {
				var field = addressFormFields[i];
				field.clearErrors();
				field.Value = null;
			}

			self.$refs.residentialAreaFormField.Value = null;
			self.ResidentialArea = null;

			self.$refs.errorPanel.clearErrors();
			self.HasErrors = false;
			self.Errors = [];
		},
		validate() {
			var self = this;

			self.HasErrors = false;
			self.Errors = [];
			self.AddressAutocompleteHasErrors = false;

			var addressValidationErrors = false;

			var addressFormFields = [
				self.$refs.streetFormField,
				self.$refs.suburbFormField,
				self.$refs.stateFormField,
				self.$refs.postcodeFormField,
				self.$refs.residentialAreaFormField,
			];

			for (var z = 0; z < addressFormFields.length; z++) {
				var addressFieldValidate = addressFormFields[z].validate();
				if (addressFieldValidate.HasErrors) {
					addressValidationErrors = true;
				}
			}

			if (addressValidationErrors && self.ManualAddressMode == false) {
				self.HasErrors = true;
				self.AddressAutocompleteHasErrors = true;
			}

			var formFields = [self.$refs.localLibraryFormField, self.$refs.termsAndConditionsFormField];

			for (var i = 0; i < formFields.length; i++) {
				var formFieldValidate = formFields[i].validate();
				if (formFieldValidate.HasErrors) {
					self.HasErrors = true;
				}
			}

			if (self.HasErrors) {
				self.Errors.unshift('Please ensure all fields are correct');
				self.$refs.errorPanel.setErrorMessages(self.Errors);
			} else {
				self.$refs.errorPanel.clearErrors();
			}
		},
		setResidentialArea(postcode) {
			var self = this;

			var residentialArea = self.ResidentialAreaPostcodeMatches.find(x => x.Postcodes.find(y => y == postcode));

			if (residentialArea) {
				self.ResidentialArea = residentialArea.Value;
				self.$refs.residentialAreaFormField.Value = residentialArea.Value;
			} else {
				self.$refs.residentialAreaFormField.Value = 'OUT';
			}
		},
		setLocalLibrary(library) {
			var self = this;

			var matchingLibrary = self.Libraries.find(x => x.Identifier.toLowerCase() == library.toLowerCase());

			if (matchingLibrary) {
				console.log('matchingLibrary', matchingLibrary);
				self.$refs.localLibraryFormField.Value = matchingLibrary.Value;
			}
		},
		submit() {
			var self = this;

			self.validate();

			if (self.HasErrors) {
				self.$refs.AddressDetails.scrollIntoView({ behavior: 'smooth' });
				return;
			}

			self.Street = self.$refs.streetFormField.Value;
			self.Suburb = self.$refs.suburbFormField.Value;
			self.State = self.$refs.stateFormField.Value;
			self.Postcode = self.$refs.postcodeFormField.Value;
			self.ResidentialArea = self.$refs.residentialAreaFormField.Value;
			self.LocalLibrary = self.$refs.localLibraryFormField.Value;
			self.TermsAndConditionsAccepted = self.$refs.termsAndConditionsFormField.Value;

			self.debug();

			self.Submitting = true;

			self.$emit('next', {
				Street: self.Street,
				Suburb: self.Suburb,
				State: self.State,
				Postcode: self.Postcode,
				ResidentialArea: self.ResidentialArea,
				LocalLibrary: self.LocalLibrary,
				TermsAndConditionsAccepted: self.TermsAndConditionsAccepted,
			});
		},
		hide() {
			this.IsHidden = true;
		},
		show() {
			this.IsHidden = false;
		},
		back() {
			var self = this;
			self.Submitting = false;
			self.HasErrors = false;
			self.Errors = [];
			self.$refs.errorPanel.clearErrors();
			self.$emit('back');
		},
		resetSubmittedAndDisplayError() {
			var self = this;
			self.Submitting = false;
			var errors = new Array('There was an error processing the form submission, please contact support.');
			self.$refs.errorPanel.setErrorMessages(errors);
		},
		debug() {
			var self = this;
			console.group('AddressDetails debug()');
			console.log('Street: ' + self.Street);
			console.log('Suburb: ' + self.Suburb);
			console.log('State: ' + self.State);
			console.log('Postcode: ' + self.Postcode);
			console.log('ResidentialArea: ' + self.ResidentialArea);
			console.log('LocalLibrary: ' + self.LocalLibrary);
			console.log('TermsAndConditionsAccepted: ' + self.TermsAndConditionsAccepted);
			if (self.HasErrors) {
				console.log(self.Errors);
			}
			console.groupEnd();
		},
	},
};
</script>
