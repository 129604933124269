import Vue from 'vue';
import VueCookies from 'vue-cookies';
import HttpClient from '@/plugins/HttpClient.vue';
import BlogArticlesDataService from '@/plugins/BlogArticlesDataService.vue';
import BlogLandingArticlesDataService from '@/plugins/BlogLandingArticlesDataService.vue';
import LocationsDataService from '@/plugins/LocationsDataService.vue';
import SignUpFormDataService from '@/plugins/SignUpFormDataService.vue';
import EventsDataService from '@/plugins/EventsLandingDataService.vue';
import AddressAutocompleteSourcePlugin from '@/plugins/AddressAutocompleteSourcePlugin.vue';
import FormFieldValidationPlugin from '@/plugins/FormFieldValidationPlugin.vue';
import LibrarySignUpFormPlugin from '@/plugins/LibrarySignUpFormPlugin.vue';

import BlogArticlesPager from '@/components/BlogArticlesPager.vue';
import BlogLandingArticlesPager from '@/components/BlogLandingArticlesPager.vue';
import BlockSearcher from '@/components/BlockSearcher.vue';
import Searcher from '@/components/Searcher.vue';
import Location from '@/components/location-modal/Location.vue';
import LocationModal from '@/components/location-modal/LocationModal.vue';
import LocationsList from '@/components/location-modal/LocationsList.vue';
import SelectedLocation from '@/components/location-modal/SelectedLocation.vue';
import SelectLocationButton from '@/components/location-modal/SelectLocationButton.vue';
import AlertsCloseButton from '@/components/AlertsCloseButton.vue';
import SignUpForm from '@/components/SignUpForm.vue';
import SearchButton from '@/components/search-modal/SearchButton.vue';
import EventsLandingPager from '@/components/events/EventsLandingPager.vue';
import 'es6-object-assign/auto';
import LibrarySignUpForm from '@/components/library-sign-up-form/LibrarySignUpForm.vue';

Vue.use(VueCookies);

Vue.use(HttpClient);
Vue.use(BlogArticlesDataService);
Vue.use(BlogLandingArticlesDataService);
Vue.use(LocationsDataService);
Vue.use(SignUpFormDataService);
Vue.use(EventsDataService);
Vue.use(AddressAutocompleteSourcePlugin);
Vue.use(FormFieldValidationPlugin);
Vue.use(LibrarySignUpFormPlugin);

Vue.component('blog-articles-pager', BlogArticlesPager);
Vue.component('blog-landing-articles-pager', BlogLandingArticlesPager);
Vue.component('block-searcher', BlockSearcher);
Vue.component('searcher', Searcher);
Vue.component('location', Location);
Vue.component('locations-list', LocationsList);
Vue.component('selected-location', SelectedLocation);
Vue.component('location-modal', LocationModal);
Vue.component('events-landing-pager', EventsLandingPager);

let searchModalBtn = document.getElementById('search-modal-btn');
if (searchModalBtn) {
	new Vue({
		el: '#search-modal-btn',
		render: h => h(SearchButton),
	});
}

let locationModalBtn = document.getElementById('location-modal-btn');
if (locationModalBtn) {
	new Vue({
		el: '#location-modal-btn',
		render: h => h(SelectLocationButton),
	});
}

let systemAlertsCloseButton = document.getElementById('yprl-system-alerts-close-button');
if (systemAlertsCloseButton) {
	new Vue({
		el: '#yprl-system-alerts-close-button',
		render: h => h(AlertsCloseButton),
	});
}

let signUpForm = document.getElementById('sign-up-form');
if (signUpForm) {
	new Vue({
		el: '#sign-up-form',
		render: h => h(SignUpForm),
	});
}

let dataBentoVueSearchBlock = document.querySelectorAll('[data-bento-vue-search-block]');
if (dataBentoVueSearchBlock) {
	dataBentoVueSearchBlock.forEach(function(searchBlock) {
		let scriptTag = document.getElementById(searchBlock.getAttribute('data-script-tag-id'));
		if (scriptTag) {
			let properties = JSON.parse(scriptTag.textContent);
			new Vue({
				render: function(h) {
					return h('block-searcher', {
						props: {
							title: properties.title,
							searchWebsiteUrl: properties.searchWebsiteUrl,
							searchCatalogueUrl: properties.searchCatalogueUrl,
							searchEventsUrl: properties.searchEventsUrl,
							advancedSearchPageUrl: properties.advancedSearchPageUrl,
						},
					});
				},
			}).$mount(searchBlock);
		}
	});
}

let vueLibrarySignUpForm = document.getElementById('vueLibrarySignUpForm');
if (vueLibrarySignUpForm) {
	var myYprlLinkUrl = vueLibrarySignUpForm.getAttribute('data-my-yprl-link-url');
	var myYprlLinkTarget = vueLibrarySignUpForm.getAttribute('data-my-yprl-link-target');
	var myYprlLinkText = vueLibrarySignUpForm.getAttribute('data-my-yprl-link-text');
	new Vue({
		el: '#vueLibrarySignUpForm',
		render: h =>
			h(LibrarySignUpForm, {
				props: {
					MyYprlLinkUrl: myYprlLinkUrl,
					MyYprlLinkTarget: myYprlLinkTarget,
					MyYprlLinkText: myYprlLinkText,
				},
			}),
	});
}

let searchForm = document.getElementById('search-form');
if (searchForm) {
	let scriptTag = document.getElementById(searchForm.getAttribute('data-script-tag-id'));
	if (scriptTag) {
		let properties = JSON.parse(scriptTag.textContent);
		new Vue({
			render: function(h) {
				return h('searcher', {
					props: {
						initialSearchTerm: properties.initialSearchTerm,
						searchWebsiteUrl: properties.searchWebsiteUrl,
						searchCatalogueUrl: properties.searchCatalogueUrl,
						searchEventsUrl: properties.searchEventsUrl,
						advancedSearchPageUrl: properties.advancedSearchPageUrl,
					},
				});
			},
		}).$mount('#search-form');
	}
}

let eventsItems = document.getElementById('eventsItems');
if (eventsItems) {
	let scriptTag = document.getElementById(eventsItems.getAttribute('data-script-tag-id'));
	if (scriptTag) {
		let properties = JSON.parse(scriptTag.textContent);
		new Vue({
			render: function(h) {
				return h('events-landing-pager', {
					props: {
						url: properties.url,
						getEventsDatesUrl: properties.getEventsDatesUrl,
						getEventsLocationsUrl: properties.getEventsLocationsUrl,
						getEventsCategoriesUrl: properties.getEventsCategoriesUrl,
						getEventsAgeGroupsUrl: properties.getEventsAgeGroupsUrl,
						getTopCategoriesUrl: properties.getTopCategoriesUrl,
						initPageNumber: properties.initPageNumber,
						pageSize: properties.pageSize,
						initialStartDate: properties.initialStartDate,
						initialEndDate: properties.initialEndDate,
						initialLocations: properties.initialLocations,
						initialCategories: properties.initialCategories,
						initialAgeGroups: properties.initialAgeGroups,
						initialKeywords: properties.initialKeywords,
					},
				});
			},
		}).$mount('#eventsItems');
	}
}

let blogLandingPageArticlesList = document.getElementById('blogLandingPageArticlesList');
if (blogLandingPageArticlesList) {
	let scriptTag = document.getElementById(blogLandingPageArticlesList.getAttribute('data-script-tag-id'));
	if (scriptTag) {
		let properties = JSON.parse(scriptTag.textContent);
		new Vue({
			render: function(h) {
				return h('blog-landing-articles-pager', {
					props: {
						url: properties.url,
						getFeaturedArticleUrl: properties.getFeaturedArticleUrl,
						getBlogArticleTagsUrl: properties.getBlogArticleTagsUrl,
						getBlogArticleDatesUrl: properties.getBlogArticleDatesUrl,
						getBlogArticleAgeGroupsUrl: properties.getBlogArticleAgeGroupsUrl,
						initPageNumber: properties.initPageNumber,
						pageSize: properties.pageSize,
						featuredArticleId: properties.featuredArticleId,
						initialTags: properties.initialTags,
						blogPageId: properties.blogPageId,
						blogPageTitle: properties.blogPageTitle,
					},
				});
			},
		}).$mount('#blogLandingPageArticlesList');
	}
}

let blogAuthorPageArticlesList = document.getElementById('blogAuthorPageArticlesList');
if (blogAuthorPageArticlesList) {
	let scriptTag = document.getElementById(blogAuthorPageArticlesList.getAttribute('data-script-tag-id'));
	if (scriptTag) {
		let properties = JSON.parse(scriptTag.textContent);
		new Vue({
			render: function(h) {
				return h('blog-articles-pager', {
					props: {
						url: properties.url,
						initPageNumber: properties.initPageNumber,
						pageSize: properties.pageSize,
						authorId: properties.authorId,
					},
				});
			},
		}).$mount('#blogAuthorPageArticlesList');
	}
}
