<template>
	<div>
		<div v-if="hasErrors">
			<ul>
				<li v-for="(error, index) in errors" :key="index">
					{{ error }}
				</li>
			</ul>
		</div>
		<section class="events-landing-filter" id="eventsFilter">
			<div class="events-landing-filter-heading">
				<h1>Events</h1>
				<div class="events-landing-filter-squiggle">
					<svg width="328" height="36" viewBox="0 0 308 36" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							class="stroke-secondary"
							d="M3 27.25L16.9986 14.6396C25.882 6.63727 39.6296 7.59221 47.3212 16.7459L48.375 18C56.2697 27.3954 70.7303 27.3954 78.625 18V18C86.5197 8.60461 100.98 8.60461 108.875 18V18C116.77 27.3954 131.23 27.3954 139.125 18V18C147.02 8.60461 161.48 8.60461 169.375 18V18C177.27 27.3954 191.761 27.3584 199.656 17.963V17.963C207.541 8.57886 222.064 8.56266 230.025 17.8827V17.8827C238.078 27.3112 252.748 27.3023 260.686 17.7766L261.453 16.856C269.112 7.66529 282.886 6.70884 291.742 14.7527L305.5 27.25"
							stroke-width="15"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
			<div class="events-landing-filter-wrapper" :class="{ open: filterOpen }">
				<div class="events-landing-filter-toggle">
					<button class="h4" :aria-pressed="filterOpen" @click="toggleOpen('filterOpen')">
						Filter
					</button>
				</div>
				<div class="events-landing-filter-content">
					<div class="events-landing-filter-terms">
						<button
							class="events-landing-filter-term colour-secondary"
							v-for="keyword in keywords"
							:key="keyword.id"
							:aria-label="'Remove ' + keyword.name"
							@click="removeKeyword(keyword.id, false)"
						>
							<span class="events-landing-filter-term-icon las la-times colour-tertiary"></span>
							{{ keyword.name }}
						</button>
					</div>
					<div class="events-landing-filter-inputs">
						<div class="events-landing-filter-date events-landing-filter-input" :class="{ open: datesOpen }">
							<div
								class="events-landing-filter-date-dropdown events-landing-filter-input-dropdown"
								@click="toggleOpen('datesOpen')"
								role="button"
								:aria-pressed="datesOpen"
								tabindex="0"
							>
								<span>Filter by Date</span>
								<span class="events-landing-filter-input-arrow custom-icon i-arrow-sm"></span>
							</div>
							<div
								class="events-landing-filter-date-menu-container events-landing-filter-input-menu-container"
								:aria-hidden="!datesOpen"
							>
								<span
									class="events-landing-filter-date-triangle events-landing-filter-input-triangle custom-icon i-menu-triangle"
								></span>
								<div class="events-landing-filter-input-head events-landing-filter-date-head">
									<button
										class="events-landing-filter-input-close"
										aria-label="Close filter"
										@touchstart="toggleOpen('datesOpen')"
									>
										<span class="las la-times colour-secondary"></span>
									</button>
									<h4 class="events-landing-filter-input-title colour-tertiary">
										Filter by date
									</h4>
								</div>
								<div class="events-landing-filter-date-menu events-landing-filter-input-menu">
									<div class="events-landing-filter-date-col" v-for="year in dates" :key="year.key">
										<p class="events-landing-filter-date-title">
											{{ year.year }}
										</p>

										<ul class="events-landing-filter-date-items no-dots">
											<li
												class="events-landing-filter-date-item"
												v-for="month in year.months"
												:key="month.key"
												:class="{
													selected:
														(startDate && startDate.year === year.year && startDate.month === month.month) ||
														(endDate && endDate.year === year.year && endDate.month === month.month),
													'selected-range':
														startDate &&
														endDate &&
														year.year >= startDate.year &&
														year.year <= endDate.year &&
														month.month > startDate.month &&
														month.month < endDate.month,
													'not-active': !month.hasContent,
												}"
											>
												<template v-if="month.hasContent">
													<button @click="dateSelected(month)">
														{{ month.monthName }}
													</button>
												</template>
												<template v-if="!month.hasContent">
													{{ month.monthName }}
												</template>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="events-landing-filter-locations events-landing-filter-input" :class="{ open: locationsOpen }">
							<div
								class="events-landing-filter-locations-dropdown events-landing-filter-input-dropdown"
								@click="toggleOpen('locationsOpen')"
								role="button"
								:aria-pressed="locationsOpen"
								tabindex="0"
							>
								<span>Filter by Locations</span>
								<span class="events-landing-filter-input-arrow custom-icon i-arrow-sm"></span>
							</div>
							<div
								class="events-landing-filter-locations-menu-container events-landing-filter-input-menu-container"
								:aria-hidden="locationsOpen"
							>
								<span
									class="events-landing-filter-locations-triangle events-landing-filter-input-triangle custom-icon i-menu-triangle"
								></span>
								<div class="events-landing-filter-input-head events-landing-filter-locations-head">
									<button
										class="events-landing-filter-input-close"
										aria-label="Close filter"
										@touchstart="toggleOpen('locationsOpen')"
									>
										<span class="las la-times colour-secondary"></span>
									</button>
									<h4 class="events-landing-filter-input-title colour-tertiary">
										Filter by Locations
									</h4>
								</div>
								<div class="events-landing-filter-locations-menu events-landing-filter-input-menu">
									<div class="events-landing-filter-locations-col">
										<ul class="events-landing-filter-locations-items no-dots">
											<li
												class="events-landing-filter-locations-item"
												v-for="location in locations"
												:key="location.id"
												:class="{
													selected: selectedLocations && selectedLocations.indexOf(location.id) >= 0,
												}"
											>
												<button @click="locationsSelected(location.id, location.name)">
													{{ location.name }}
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="events-landing-filter-categories events-landing-filter-input" :class="{ open: categoriesOpen }">
							<div
								class="events-landing-filter-categories-dropdown events-landing-filter-input-dropdown"
								@click="toggleOpen('categoriesOpen')"
								role="button"
								:aria-pressed="categoriesOpen"
								tabindex="0"
							>
								<span>Filter by Categories</span>
								<span class="events-landing-filter-input-arrow custom-icon i-arrow-sm"></span>
							</div>
							<div
								class="events-landing-filter-categories-menu-container events-landing-filter-input-menu-container"
								:aria-hidden="!categoriesOpen"
							>
								<span
									class="events-landing-filter-categories-triangle events-landing-filter-input-triangle custom-icon i-menu-triangle"
								></span>
								<div class="events-landing-filter-input-head events-landing-filter-categories-head">
									<button
										class="events-landing-filter-input-close"
										aria-label="Close filter"
										@touchstart="toggleOpen('categoriesOpen')"
									>
										<span class="las la-times colour-secondary"></span>
									</button>
									<h4 class="events-landing-filter-input-title colour-tertiary">
										Filter by Categories
									</h4>
								</div>
								<div class="events-landing-filter-categories-menu events-landing-filter-input-menu">
									<div class="events-landing-filter-categories-col">
										<ul class="events-landing-filter-categories-items no-dots">
											<li
												class="events-landing-filter-categories-item"
												v-for="category in categories"
												:key="category.id"
												:class="{
													selected: selectedCategories && selectedCategories.indexOf(category.id) >= 0,
												}"
											>
												<button @click="categoriesSelected(category.id, category.name)">
													{{ category.name }}
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<!-- toggle open class -->
						<div class="events-landing-filter-age events-landing-filter-input" :class="{ open: ageGroupsOpen }">
							<div
								class="events-landing-filter-age-dropdown events-landing-filter-input-dropdown"
								@click="toggleOpen('ageGroupsOpen')"
								role="button"
								:aria-pressed="ageGroupsOpen"
								tabindex="0"
							>
								<span>Filter by Age Group</span>
								<span class="events-landing-filter-input-arrow custom-icon i-arrow-sm"></span>
							</div>
							<div
								class="events-landing-filter-age-menu-container events-landing-filter-input-menu-container"
								:aria-hidden="!ageGroupsOpen"
							>
								<span
									class="events-landing-filter-age-triangle events-landing-filter-input-triangle custom-icon i-menu-triangle"
								></span>
								<div class="events-landing-filter-input-head events-landing-filter-age-head">
									<button
										class="events-landing-filter-input-close"
										aria-label="Close filter"
										@touchstart="toggleOpen('ageGroupsOpen')"
									>
										<span class="las la-times colour-secondary"></span>
									</button>
									<h4 class="events-landing-filter-input-title colour-tertiary">
										Filter by Age Group
									</h4>
								</div>
								<div class="events-landing-filter-age-menu events-landing-filter-input-menu">
									<div class="events-landing-filter-age-col">
										<ul class="events-landing-filter-age-items no-dots">
											<li
												class="events-landing-filter-age-item"
												v-for="ageGroup in ageGroups"
												:key="ageGroup"
												:class="{
													selected: selectedAgeGroups.indexOf(ageGroup) >= 0,
												}"
											>
												<button @click="ageGroupSelected(ageGroup)">
													{{ ageGroup }}
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="events-landing-filter-clear">
						<button
							class="events-landing-filter-clear-button colour-secondary"
							aria-label="Clear All Filters"
							@click="clearFilters"
						>
							<span class="events-landing-filter-clear-icon las la-times colour-tertiary"></span>
							Clear All Filters
						</button>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="events-top-categories" :class="{ open: topCategoriesOpen }">
				<div class="events-top-categories-toggle">
					<button class="h4" :aria-pressed="topCategoriesOpen" @click="toggleOpen('topCategoriesOpen')">
						Top 10 Categories
					</button>
				</div>
				<ul class="events-top-categories-listing">
					<li v-for="category in topCategories" :key="category.id">
						<button
							class="events-top-categories-listing-card"
							:class="{
								selected: selectedCategories && selectedCategories.indexOf(category.id) >= 0,
								[category.color]: category.color,
							}"
							:key="category.id"
							@click="categoriesSelected(category.id, category.name)"
						>
							<div class="events-top-categories-listing-card-content">
								<div class="events-top-categories-listing-card-icon">
									<img :src="category.icon" />
								</div>
								<p class="events-top-categories-listing-card-title">
									{{ category.name }}
								</p>
							</div>
						</button>
					</li>
				</ul>
			</div>
		</section>

		<section>
			<div class="events-listing" id="eventsListing">
				<a :href="data.url" class="events-listing-card" v-for="data in pageData" :key="data.id" target="_blank">
					<div class="events-listing-card-back back-primary">
						<div
							v-if="data.imageUrl !== null"
							class="events-listing-card-image base-back-image"
							role="img"
							aria-label="Alt text"
							v-bind:style="{
								'background-image': 'url(' + data.imageUrl + ')',
							}"
						>
							<div class="events-listing-card-line back-secondary"></div>
						</div>
						<div class="events-listing-card-content" :data-category="data.categoryHeading">
							<div>
								<h3>{{ data.title }}</h3>
								<h6 class="events-listing-card-time font-bold colour-tertiary">
									{{ data.date.replace(/^0+/, '') }},
									<span class="events-listing-card-time-avoid-break">
										{{ data.startTime.replace(/^0+/, '') }} to {{ data.endTime.replace(/^0+/, '') }}
									</span>
								</h6>
								<p class="font-bold">{{ data.locationHeading }}</p>
								<p class="events-listing-card-text" v-html="data.description + '...'"></p>
							</div>
							<div class="btn-link-alt colour-tertiary">
								<span class="btn-container">Learn More</span>
							</div>
						</div>
					</div>
				</a>
			</div>

			<div class="pagination-container" v-if="totalPages > 1">
				<nav class="pagination back-primary" role="navigation" aria-label="Articles pagination navigation">
					<ul class="no-dots">
						<li v-if="pageNumber > 1">
							<span
								role="button"
								tabindex="1"
								class="pagination-link h4 pagination-prev colour-tertiary"
								@click="pagination(pageNumber - 1)"
								aria-label="Previous"
							></span>
						</li>
						<li v-for="page in pages" :key="page">
							<span
								role="button"
								tabindex="1"
								v-if="page !== pageNumber"
								class="pagination-link h4"
								@click="pagination(page)"
								:aria-label="`Page ${page}`"
							>
								{{ page }}
							</span>
							<span
								role="button"
								tabindex="1"
								v-if="page === pageNumber"
								class="pagination-link h4 colour-secondary"
								@click="pagination(page)"
								:aria-label="`Current Page, Page ${page}`"
								aria-current="true"
							>
								{{ page }}
							</span>
						</li>
						<li v-if="totalPages > 7 && maxPageNumber !== totalPages" class="pagination-seperator">
							...
						</li>
						<li v-if="maxPageNumber !== totalPages">
							<span
								role="button"
								tabindex="1"
								class="pagination-link h4"
								@click="pagination(totalPages)"
								:aria-label="`Page ${totalPages}`"
							>
								{{ totalPages }}
							</span>
						</li>
						<li v-if="pageNumber !== totalPages">
							<span
								role="button"
								tabindex="1"
								class="pagination-link h4 pagination-next colour-tertiary"
								@click="pagination(pageNumber + 1)"
								aria-label="Next"
							></span>
						</li>
					</ul>
				</nav>
				<div class="pagination-number">
					<h4>{{ startItemNumber }}-{{ endItemNumber }} of {{ totalItems }}</h4>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Vue from 'vue';
export default {
	props: {
		url: {
			type: String,
			required: true,
		},
		getEventsDatesUrl: {
			type: String,
			required: true,
		},
		getEventsLocationsUrl: {
			type: String,
			required: true,
		},
		getEventsCategoriesUrl: {
			type: String,
			required: true,
		},
		getEventsAgeGroupsUrl: {
			type: String,
			required: true,
		},
		getTopCategoriesUrl: {
			type: String,
			required: true,
		},
		initPageNumber: {
			type: Number,
			required: false,
			default: 1,
		},
		pageSize: {
			type: Number,
			required: false,
			default: 12,
		},
		initialStartDate: {
			type: String,
			required: false,
		},
		initialEndDate: {
			type: String,
			required: false,
		},
		initialLocations: {
			type: Array,
			required: false,
		},
		initialCategories: {
			type: Array,
			required: false,
		},
		initialAgeGroups: {
			type: Array,
			required: false,
		},
		initialKeywords: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			startPageNumber: this.initPageNumber,
			pageNumber: this.initPageNumber,
			pageData: [],
			totalItems: 0,
			totalPages: 0,
			maxPageNumber: 0,
			startItemNumber: 1,
			endItemNumber: this.pageSize,
			pages: [],
			errors: [],
			hasErrors: false,
			dates: [],
			startDate: null,
			endDate: null,
			locations: [],
			selectedLocations: [],
			categories: [],
			selectedCategories: [],
			topCategories: [],
			// selectedCategories: [],
			ageGroups: [],
			selectedAgeGroups: [],
			selectedKeywords: [],
			keywords: [],
			filterOpen: false,
			datesOpen: false,
			locationsOpen: false,
			categoriesOpen: false,
			ageGroupsOpen: false,
			topCategoriesOpen: false,
			openedFilter: null,
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			let self = this;

			self.maxPageNumber = self.pageNumber + 7;

			for (let i = self.pageNumber; i < self.maxPageNumber; i++) {
				self.pages.push(i);
			}

			if (self.initialStartDate && self.initialStartDate.length > 0) {
				let date = self.initialStartDate.split('-');
				self.startDate = { day: date[2], month: date[1], year: date[0] };
			}
			if (self.initialEndDate && self.initialEndDate.length > 0) {
				let date = self.initialEndDate.split('-');
				self.endDate = { day: date[2], month: date[1], year: date[0] };
			}
			if (self.initialLocations && self.initialLocations.length > 0) {
				for (let i = 0; i < self.initialLocations.length; i++) {
					self.selectedLocations.push(self.initialLocations[i]);
				}
			}
			if (self.initialCategories && self.initialCategories.length > 0) {
				for (let i = 0; i < self.initialCategories.length; i++) {
					self.selectedCategories.push(self.initialCategories[i]);
				}
			}
			if (self.initialAgeGroups && self.initialAgeGroups.length > 0) {
				for (let i = 0; i < self.initialAgeGroups.length; i++) {
					self.selectedAgeGroups.push(self.initialAgeGroups[i]);
					let keyword = {
						id: self.initialAgeGroups[i],
						name: self.initialAgeGroups[i],
						type: 'agegroup',
					};
					self.keywords.push(keyword);
				}
			}
			if (self.initialKeywords) {
				let keyword = {
					id: self.initialKeywords,
					name: self.initialKeywords,
					type: 'keywords',
				};
				self.keywords.push(keyword);
				self.selectedKeywords = self.initialKeywords.split(' ');
			}

			self.getEventsDates();
			self.getEventsLocations();
			self.getEventsCategories();
			self.getEventsAgeGroups();
			self.getTopCategories();
			self.getPage(self.startPageNumber, true);
		},
		getEventsDates() {
			let self = this;

			Vue.getDataFromUrl(self.getEventsDatesUrl, self.onGetEventsDatesSuccess, self.onGetError);
		},
		getEventsLocations() {
			let self = this;

			Vue.getDataFromUrl(self.getEventsLocationsUrl, self.onGetEventsLocationsSuccess, self.onGetError);
		},
		getEventsCategories() {
			let self = this;

			Vue.getDataFromUrl(self.getEventsCategoriesUrl, self.onGetEventsCategoriesSuccess, self.onGetError);
		},
		getEventsAgeGroups() {
			let self = this;

			Vue.getDataFromUrl(self.getEventsAgeGroupsUrl, self.onGetEventsAgeGroupsSuccess, self.onGetError);
		},
		getTopCategories() {
			let self = this;

			Vue.getDataFromUrl(self.getTopCategoriesUrl, self.onGetTopCategoriesSuccess, self.onGetError);
		},
		getPage(page, initRun) {
			let self = this;

			if (!initRun && page && page === self.pageNumber) {
				return;
			}

			Vue.getFilteredPageOfEvents(
				self.url,
				self.startDate,
				self.endDate,
				self.selectedLocations,
				self.selectedCategories,
				self.selectedAgeGroups,
				self.selectedKeywords,
				page !== null ? page : self.pageNumber,
				self.pageSize,
				self.onGetPageSuccess,
				self.onGetError
			);
		},
		toggleOpen(varName) {
			let self = this;
			if (varName === 'filterOpen' || varName === 'topCategoriesOpen') {
				self[varName] = self[varName] ? false : true;
			} else {
				if (self.openedFilter === varName) {
					self[varName] = false;
					self.openedFilter = null;
				} else if (self.openedFilter === null) {
					self[varName] = true;
					self.openedFilter = varName;
				} else {
					self[self.openedFilter] = false;
					self[varName] = true;
					self.openedFilter = varName;
				}
			}
		},
		pagination(pagenumber) {
			let self = this;
			let filter = document.getElementById('eventsListing').offsetTop;
			console.log(filter);
			window.scrollTo({ top: filter + 150, behavior: 'smooth' });
			self.getPage(pagenumber);
		},
		addKeyword(id, name, type) {
			let self = this;
			let keyword = { id: id, name: name, type: type };
			self.keywords.push(keyword);

			const url = new URL(window.location);

			if (type === 'startdate') {
				url.searchParams.set('startDate', keyword.id);
				window.history.replaceState({}, '', url);
			} else if (type === 'enddate') {
				url.searchParams.set('endDate', keyword.id);
				window.history.replaceState({}, '', url);
			} else if (type === 'location') {
				url.searchParams.set('locations', self.selectedLocations.join('+'));
				window.history.replaceState({}, '', url);
			} else if (type === 'category') {
				url.searchParams.set('categories', self.selectedCategories.join('+'));
				window.history.replaceState({}, '', url);
			} else if (type === 'agegroup') {
				url.searchParams.set('ageGroups', self.selectedAgeGroups.join('+'));
				window.history.replaceState({}, '', url);
			}
		},
		removeKeyword(keyword, fromSelectedDate) {
			if (!keyword || keyword.length <= 0) {
				return;
			}

			let self = this;

			let keywordIndex = self.keywords.findIndex(keywordIndex => keywordIndex.id === keyword);

			if (keywordIndex < 0) {
				return;
			}

			let keywordToRemove = self.keywords[keywordIndex];
			const url = new URL(window.location);

			if (keywordToRemove.type === 'startdate') {
				url.searchParams.delete('startDate');
				if (fromSelectedDate !== true) {
					self.startDate = null;
				}

				if (url.searchParams.has('endDate')) {
					let newStartDate = url.searchParams.get('endDate');
					let endDateIndex = self.keywords.findIndex(keywordIndex => keywordIndex.id === newStartDate);
					url.searchParams.set('startDate', newStartDate);
					self.keywords[endDateIndex].type = 'startdate';
					url.searchParams.delete('endDate');
					self.startDate = self.endDate;
					self.endDate = null;
				}

				window.history.replaceState({}, '', url);
			} else if (keywordToRemove.type === 'enddate') {
				url.searchParams.delete('endDate');

				window.history.replaceState({}, '', url);
				if (fromSelectedDate !== true) {
					self.endDate = null;
				}
			} else if (keywordToRemove.type === 'location') {
				let locationIndex = self.selectedLocations.indexOf(keywordToRemove.id);
				self.selectedLocations.splice(locationIndex, 1);

				if (self.selectedLocations.length === 0) {
					url.searchParams.delete('locations');
				} else {
					url.searchParams.set('locations', self.selectedLocations.join('+'));
				}

				window.history.replaceState({}, '', url);
			} else if (keywordToRemove.type === 'category') {
				let categoryIndex = self.selectedCategories.indexOf(keywordToRemove.id);
				self.selectedCategories.splice(categoryIndex, 1);

				if (self.selectedCategories.length === 0) {
					url.searchParams.delete('categories');
				} else {
					url.searchParams.set('categories', self.selectedCategories.join('+'));
				}

				window.history.replaceState({}, '', url);
			} else if (keywordToRemove.type === 'agegroup') {
				let ageGroupIndex = self.selectedAgeGroups.indexOf(keywordToRemove.id);
				self.selectedAgeGroups.splice(ageGroupIndex, 1);

				if (self.selectedAgeGroups.length === 0) {
					url.searchParams.delete('ageGroups');
				} else {
					url.searchParams.set('ageGroups', self.selectedAgeGroups.join('+'));
				}

				window.history.replaceState({}, '', url);
			} else if (keywordToRemove.type === 'keywords') {
				url.searchParams.delete('keywords');
				self.selectedKeywords = [];

				window.history.replaceState({}, '', url);
			}

			self.keywords.splice(keywordIndex, 1);

			self.pageNumber = 1;
			self.getPage(null);
		},
		dateSelected(date) {
			let self = this;
			let dateId = `${date.year}-${date.month}-${date.day}`;
			let dateName = `${date.monthName} ${date.year}`;

			if (!self.startDate) {
				if (self.endDate !== null) {
					self.removeKeyword(`${self.endDate.year}-${self.endDate.month}-${self.endDate.day}`, true);
				}

				self.startDate = date;
				self.endDate = null;

				self.addKeyword(dateId, dateName, 'startdate');

				self.pageNumber = 1;
				self.getPage(null);
				return;
			}

			if (self.startDate && self.startDate.month === date.month && self.startDate.year === date.year) {
				if (self.endDate !== null) {
					self.removeKeyword(`${self.endDate.year}-${self.endDate.month}-${self.endDate.day}`, true);
					self.removeKeyword(`${self.startDate.year}-${self.startDate.month}-${self.startDate.day}`, true);

					self.startDate = self.endDate;
					self.endDate = null;

					let startDateId = `${self.startDate.year}-${self.startDate.month}-${self.startDate.day}`;
					let startDateName = `${self.startDate.monthName} ${self.startDate.year}`;

					self.addKeyword(startDateId, startDateName, 'startdate');
				} else {
					self.removeKeyword(`${self.startDate.year}-${self.startDate.month}-${self.startDate.day}`, true);
					self.startDate = null;
				}

				self.pageNumber = 1;
				self.getPage(null);
				return;
			}

			if (self.endDate && self.endDate.month === date.month && self.endDate.year === date.year) {
				if (self.endDate !== null) {
					self.removeKeyword(`${self.endDate.year}-${self.endDate.month}-${self.endDate.day}`, true);
				}
				self.endDate = null;

				self.pageNumber = 1;
				self.getPage(null);
				return;
			}

			if (date.year === self.startDate.year) {
				if (date.month < self.startDate.month) {
					if (self.endDate !== null) {
						self.removeKeyword(`${self.startDate.year}-${self.startDate.month}-${self.startDate.day}`, true);

						self.startDate = date;

						self.addKeyword(dateId, dateName, 'startdate');
					} else {
						self.removeKeyword(`${self.startDate.year}-${self.startDate.month}-${self.startDate.day}`, true);

						let endDateId = `${self.startDate.year}-${self.startDate.month}-${self.startDate.day}`;
						let endDateName = `${self.startDate.monthName} ${self.startDate.year}`;

						self.endDate = self.startDate;
						self.startDate = date;

						self.addKeyword(endDateId, endDateName, 'enddate');
						self.addKeyword(dateId, dateName, 'startdate');
					}
				} else {
					if (self.endDate !== null) {
						self.removeKeyword(`${self.endDate.year}-${self.endDate.month}-${self.endDate.day}`, true);
					}
					self.endDate = date;
					self.addKeyword(dateId, dateName, 'enddate');
				}
			} else if (date.year < self.startDate.year) {
				if (self.endDate !== null) {
					self.removeKeyword(`${self.endDate.year}-${self.endDate.month}-${self.endDate.day}`, true);
				}
				if (self.startDate !== null) {
					self.removeKeyword(`${self.startDate.year}-${self.startDate.month}-${self.startDate.day}`, true);
				}
				self.endDate = self.startDate;
				self.startDate = date;

				let endDateId = `${self.endDate.year}-${self.endDate.month}-${self.endDate.day}`;
				let endDateName = `${self.endDate.monthName} ${self.endDate.year}`;

				self.addKeyword(endDateId, endDateName, 'enddate');
				self.addKeyword(dateId, dateName, 'startdate');
			} else {
				self.endDate = date;
				self.addKeyword(dateId, dateName, 'enddate');
			}

			self.pageNumber = 1;
			self.getPage(null);
		},
		locationsSelected(locationId, locationName) {
			let self = this;
			let locationIndex = self.selectedLocations.indexOf(locationId);

			if (locationIndex >= 0) {
				self.removeKeyword(locationId);
			} else {
				self.selectedLocations.push(locationId);
				self.addKeyword(locationId, locationName, 'location');
			}

			self.pageNumber = 1;
			self.getPage(null);
		},
		categoriesSelected(categoryId, categoryName) {
			let self = this;
			let categoryIndex = self.selectedCategories.indexOf(categoryId);

			if (categoryIndex >= 0) {
				self.removeKeyword(categoryId);
			} else {
				self.selectedCategories.push(categoryId);
				self.addKeyword(categoryId, categoryName, 'category');
			}

			self.pageNumber = 1;
			self.getPage(null);
		},
		ageGroupSelected(ageGroup) {
			let self = this;
			let ageGroupIndex = self.selectedAgeGroups.indexOf(ageGroup);

			if (ageGroupIndex >= 0) {
				self.removeKeyword(ageGroup);
			} else {
				self.selectedAgeGroups.push(ageGroup);
				self.addKeyword(ageGroup, ageGroup, 'agegroup');
			}

			self.pageNumber = 1;
			self.getPage(null);
		},
		onGetEventsDatesSuccess(response) {
			if (!response || !response.data) {
				return;
			}

			let self = this;

			for (let i = 0; i < response.data.length; i++) {
				self.dates.push(response.data[i]);
			}

			if (self.initialStartDate) {
				let date = self.initialStartDate.split('-');

				let year = self.dates.find(year => year.year == date[0]);
				let month = year.months.find(month => month.month == date[1]);
				if (month) {
					self.startDate = month;

					let startDateId = `${self.startDate.year}-${self.startDate.month}-${self.startDate.day}`;
					let startDateName = `${self.startDate.monthName} ${self.startDate.year}`;

					let keyword = {
						id: startDateId,
						name: startDateName,
						type: 'startdate',
					};
					self.keywords.push(keyword);
				}
			}
			if (self.initialEndDate) {
				let date = self.initialEndDate.split('-');

				let year = self.dates.find(year => year.year == date[0]);
				let month = year.months.find(month => month.month == date[1]);
				if (month) {
					self.endDate = month;

					let endDateId = `${self.endDate.year}-${self.endDate.month}-${self.endDate.day}`;
					let endDateName = `${self.endDate.monthName} ${self.endDate.year}`;

					let keyword = { id: endDateId, name: endDateName, type: 'enddate' };
					self.keywords.push(keyword);
				}
			}
		},
		onGetEventsLocationsSuccess(response) {
			if (!response || !response.data) {
				return;
			}

			let self = this;

			for (let i = 0; i < response.data.length; i++) {
				self.locations.push(response.data[i]);
			}

			if (self.selectedLocations && self.selectedLocations.length > 0) {
				for (let i = 0; i < self.selectedLocations.length; i++) {
					let locationId = self.selectedLocations[i];
					let location = self.locations.find(location => location.id === locationId);
					let keyword = {
						id: location.id,
						name: location.name,
						type: 'location',
					};
					self.keywords.push(keyword);
				}
			}
		},
		onGetEventsCategoriesSuccess(response) {
			if (!response || !response.data) {
				return;
			}

			let self = this;

			for (let i = 0; i < response.data.length; i++) {
				self.categories.push(response.data[i]);
			}

			if (self.selectedCategories && self.selectedCategories.length > 0) {
				for (let i = 0; i < self.selectedCategories.length; i++) {
					let categoryId = self.selectedCategories[i];
					let category = self.categories.find(category => category.id === categoryId);
					let keyword = {
						id: category.id,
						name: category.name,
						type: 'category',
					};
					self.keywords.push(keyword);
				}
			}
		},
		onGetTopCategoriesSuccess(response) {
			if (!response || !response.data) {
				return;
			}

			let self = this;
			console.log('topCategories data', response.data);
			for (let i = 0; i < response.data.length; i++) {
				self.topCategories.push(response.data[i]);
			}

			if (self.selectedCategories && self.selectedCategories.length > 0) {
				for (let i = 0; i < self.selectedCategories.length; i++) {
					let categoryId = self.selectedCategories[i];
					let topCategory = self.topCategories.find(category => category.id === categoryId);
					let keyword = {
						id: topCategory.id,
						name: topCategory.name,
						type: 'category',
					};
					self.keywords.push(keyword);
				}
			}
		},
		onGetEventsAgeGroupsSuccess(response) {
			if (!response || !response.data) {
				return;
			}

			let self = this;

			for (let i = 0; i < response.data.length; i++) {
				self.ageGroups.push(response.data[i]);
			}
		},
		onGetPageSuccess(response) {
			let self = this;

			self.hasErrors = false;
			self.errors.length = 0;

			self.pageData.length = 0;
			self.pageData.push(...response.data.items);
			self.totalItems = response.data.totalItems;
			self.totalPages = response.data.totalPages;
			self.pageNumber = response.data.pageNumber;

			if (self.pageNumber === 1) {
				self.maxPageNumber = self.pageNumber + 6;
				self.startPageNumber = self.pageNumber;
			} else if (self.pageNumber === 2) {
				self.maxPageNumber = self.pageNumber + 5;
				self.startPageNumber = self.pageNumber - 1;
			} else if (self.pageNumber === 3) {
				self.maxPageNumber = self.pageNumber + 4;
				self.startPageNumber = self.pageNumber - 2;
			} else {
				self.maxPageNumber = self.pageNumber + 3;
				self.startPageNumber = self.pageNumber - 3;
			}

			if (self.maxPageNumber > self.totalPages) {
				self.maxPageNumber = self.totalPages;
			}

			self.startItemNumber = self.pageNumber == 1 ? 1 : (self.pageNumber - 1) * self.pageSize + 1;
			self.endItemNumber = self.pageNumber == 1 ? self.pageSize : self.startItemNumber + self.pageData.length - 1;

			self.pages.length = 0;

			for (let i = self.startPageNumber; i <= self.maxPageNumber; i++) {
				self.pages.push(i);
			}
		},
		clearFilters() {
			let self = this;
			self.startDate = null;
			self.endDate = null;
			self.keywords.length = 0;
			self.selectedLocations.length = 0;
			self.selectedCategories.length = 0;
			self.selectedAgeGroups.length = 0;
			self.selectedKeywords.length = 0;

			const url = new URL(window.location);

			url.searchParams.delete('startDate');
			url.searchParams.delete('endDate');
			url.searchParams.delete('locations');
			url.searchParams.delete('categories');
			url.searchParams.delete('ageGroups');
			url.searchParams.delete('keywords');

			window.history.replaceState({}, '', url);

			self.pageNumber = 1;
			self.getPage(null);
		},
		onGetError() {
			let self = this;
			self.hasErrors = true;
			self.errors.length = 0;
			self.errors.push('Something went wrong. Please refresh the page and try again');
			self.pages.length = 0;
			self.pageData.length = 0;
			self.pageNumber = self.initPageNumber;
			self.totalItems = 0;
			self.totalPages = 0;
			self.maxPageNumber = 0;
			self.startItemNumber = 0;
			self.endItemNumber = 0;
		},
	},
};
</script>
