<script>
export default {
  install(Vue) {
    Vue.getFilteredPageOfEvents = function(
      url,
      startDate,
      endDate,
      locations,
      categories,
      ageGroups,
      keywords,
      pageNumber,
      pageSize,
      successCallback,
      errorCallback
    ) {      
      let fullUrl = `${url}?pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (startDate !== null) {
        let startDateString = `${startDate.year}-${startDate.month}-${startDate.day}`;
        fullUrl += `&startDate=${startDateString}`;
      }
      
      if (endDate !== null) {
        let endDateString = `${endDate.year}-${endDate.month}-${endDate.day}`;
        fullUrl += `&endDate=${endDateString}`;
      }

      if (locations && locations.length > 0) {
        fullUrl += `&locations=${locations.join("+")}`;
      }
      
      if (categories && categories.length > 0) {
        fullUrl += `&categories=${categories.join("+")}`;
      }

      if (ageGroups && ageGroups.length > 0) {
        fullUrl += `&ageGroups=${ageGroups.join("+")}`;
      }

      if (keywords && keywords.length > 0) {
        fullUrl += `&keyWord=${keywords.join("+")}`;
      }

      Vue.getDataFromUrl(
        fullUrl,
        successCallback,
        errorCallback
      );
  
    };
    
  }
};
</script>