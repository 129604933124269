<template>
	<div id="LoginDetails" ref="LoginDetails" :class="{ 'd-none': IsHidden }">
		<div class="form">
			<h2>Login Details</h2>
			<ErrorPanel ref="errorPanel" />
			<div class="form-wrapper">
				<div class="form-row">
					<div class="form-full-col form-col">
						<TextBox
							ref="passwordFormField"
							LabelText="Password"
							Type="password"
							Name="password"
							PlaceholderText="Password"
							:IsRequired="true"
							ValidationType="Custom"
							CustomValidation="^[a-zA-Z0-9]{4,10}$"
							HelpText="Your password must be 4-10 characters long and contain only letters and/or numbers"
							InitialValue=""
						/>
						<TextBox
							ref="confirmPasswordFormField"
							LabelText="Confirm Password"
							Type="password"
							Name="password"
							PlaceholderText="Confirm Password"
							:IsRequired="true"
							ValidationType="Custom"
							CustomValidation="^[a-zA-Z0-9]{4,10}$"
							InitialValue=""
						/>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-col form-navigation-left">
					<button type="submit" class="btn btn-primary btn-back back-secondary" :disabled="Submitting" @click="back()">
						<span class="las la-chevron-circle-left"></span>
						Previous
					</button>
				</div>
				<div class="form-col form-navigation-right">
					<button type="submit" class="btn btn-primary back-secondary" :disabled="Submitting" @click="submit()">
						Next
						<span class="las la-sign-in-alt"></span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ErrorPanel from '@/components/library-sign-up-form/ErrorPanel.vue';
import TextBox from '@/components/form-fields/TextBox.vue';

export default {
	name: 'Step04LoginDetails',
	components: {
		ErrorPanel,
		TextBox,
	},
	data() {
		return {
			IsHidden: true,
			HasErrors: false,
			Errors: [],
			Submitting: false,

			Password: null,
			ConfirmPassword: null,
		};
	},
	methods: {
		init(debug) {
			var self = this;
			if (debug.enabled) {
				self.$refs.passwordFormField.Value = debug.password;
				self.$refs.confirmPasswordFormField.Value = debug.confirmPassword;
			}
		},
		validate() {
			var self = this;

			self.HasErrors = false;
			self.Errors = [];
			self.Submitting = false;

			var passwordFormField = self.$refs.passwordFormField;
			var confirmPasswordFormField = self.$refs.confirmPasswordFormField;

			var formFields = [passwordFormField, confirmPasswordFormField];

			for (var i = 0; i < formFields.length; i++) {
				var validate = formFields[i].validate();
				if (validate.HasErrors) {
					self.HasErrors = true;
				}
			}

			if (self.HasErrors == false && passwordFormField.Value !== confirmPasswordFormField.Value) {
				passwordFormField.HasErrors = true;
				passwordFormField.Errors = ['Please ensure your passwords match'];
				confirmPasswordFormField.HasErrors = true;
				self.HasErrors = true;
			}

			if (self.HasErrors) {
				self.Errors.unshift('Please ensure all fields are correct');
				self.$refs.errorPanel.setErrorMessages(self.Errors);
			} else {
				self.$refs.errorPanel.clearErrors();
			}
		},
		submit() {
			var self = this;

			self.validate();

			if (self.HasErrors) {
				self.$refs.LoginDetails.scrollIntoView({ behavior: 'smooth' });
				return;
			}

			self.Password = self.$refs.passwordFormField.Value;
			self.ConfirmPassword = self.$refs.confirmPasswordFormField.Value;

			self.debug();

			self.$emit('next', {
				Password: self.Password,
				ConfirmPassword: self.Password,
			});
		},
		hide() {
			this.IsHidden = true;
		},
		show() {
			this.IsHidden = false;
		},
		back() {
			this.$emit('back');
		},
		debug() {
			var self = this;
			console.group('LoginDetails debug()');
			console.log('Password: ' + self.Password);
			console.log('HasErrors: ' + self.HasErrors);
			if (self.HasErrors) {
				console.log(self.Errors);
			}
			console.groupEnd();
		},
	},
};
</script>
