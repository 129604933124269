<script>
export default {
	install(Vue) {
		Vue.validateFormFieldValue = function(value, isRequired, requiredMsg) {
			if (isRequired) {
				var msg =
					typeof requiredMsg !== 'undefined' && requiredMsg !== null && requiredMsg.length > 0
						? requiredMsg
						: 'This field is required';

				if (typeof value === 'string' && value.length <= 0) {
					return {
						HasErrors: true,
						Errors: [msg],
					};
				}

				if (typeof value === 'boolean' && !value) {
					return {
						HasErrors: true,
						Errors: [msg],
					};
				}

				if (Array.isArray(value) && value.length <= 0) {
					return {
						HasErrors: true,
						Errors: [msg],
					};
				}

				if (typeof value === 'undefined' || value === null) {
					return {
						HasErrors: true,
						Errors: [msg],
					};
				}
			}

			return {
				HasErrors: false,
				Errors: [],
			};
		};

		Vue.validateFormTextFieldValue = function(
			value,
			isRequired,
			requiredMsg,
			validationType,
			validationMsg,
			customValidationPattern
		) {
			if (isRequired) {
				if (typeof value === 'undefined' || value === null || value.length <= 0) {
					var msg =
						typeof requiredMsg !== 'undefined' && requiredMsg !== null && requiredMsg.length > 0
							? requiredMsg
							: 'This field is required';

					return {
						HasErrors: true,
						Errors: [msg],
					};
				}
			} else {
				if (typeof value === 'undefined' || value === null || value.length <= 0) {
					return {
						HasErrors: false,
						Errors: [],
					};
				}
			}

			var pattern;
			var validationErrorMsg;

			switch (validationType) {
				case 'Phone':
					pattern = /^\d{10}$/i;
					validationErrorMsg =
						typeof validationMsg !== 'undefined' && validationMsg !== null && validationMsg.length > 0
							? validationMsg
							: 'This field expects a valid phone number without spaces or special characters';
					break;
				case 'Email':
					pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
					validationErrorMsg =
						typeof validationMsg !== 'undefined' && validationMsg !== null && validationMsg.length > 0
							? validationMsg
							: 'This field expects a valid email address';
					break;
				case 'Text Only':
					pattern = /[a-z'\-\s]+/i;
					validationErrorMsg =
						typeof validationMsg !== 'undefined' && validationMsg !== null && validationMsg.length > 0
							? validationMsg
							: 'This field expects text only';
					break;
				case 'Numbers Only':
					pattern = /[0-9]+/i;
					validationErrorMsg =
						typeof validationMsg !== 'undefined' && validationMsg !== null && validationMsg.length > 0
							? validationMsg
							: 'This field expects numbers only';
					break;
				case 'Alphanumeric Only':
					pattern = /^[a-z0-9]+$/i;
					validationErrorMsg =
						typeof validationMsg !== 'undefined' && validationMsg !== null && validationMsg.length > 0
							? validationMsg
							: 'This field expects alphanumeric characters only';
					break;
				case 'Custom':
					if (
						typeof customValidationPattern === 'undefined' ||
						customValidationPattern === null ||
						customValidationPattern.length < 0
					) {
						pattern = /\.?/i;
					} else {
						pattern = new RegExp(customValidationPattern);
					}
					validationErrorMsg =
						typeof validationMsg !== 'undefined' && validationMsg !== null && validationMsg.length > 0
							? validationMsg
							: 'This field is not valid';
					break;
				default:
					pattern = null;
					validationErrorMsg = null;
					break;
			}

			if (pattern !== null) {
				if (!pattern.test(value)) {
					return {
						HasErrors: true,
						Errors: [validationErrorMsg],
					};
				}
			}

			return {
				HasErrors: false,
				Errors: [],
			};
		};
	},
};
</script>
