<template>
	<div id="ContactDetails" ref="ContactDetails" :class="{ 'd-none': IsHidden }">
		<div class="form">
			<h2>Contact Details</h2>
			<ErrorPanel ref="errorPanel" />
			<div class="form-wrapper">
				<div class="form-row">
					<div class="form-full-col form-col">
						<TextBox
							ref="emailAddressFormField"
							LabelText="Email Address"
							Type="email"
							Name="emailAddress"
							PlaceholderText="Email Address"
							:IsRequired="true"
							RequiredMessage=""
							ValidationType="Email"
							ValidationMessage=""
							CustomValidation=""
							AdditionalCssClasses=""
							InitialValue=""
						/>
						<TextBox
							ref="phoneNumberFormField"
							LabelText="Phone Number"
							Type="tel"
							Name="phoneNumber"
							PlaceholderText="Phone Number"
							:IsRequired="false"
							RequiredMessage=""
							ValidationType="Phone"
							ValidationMessage=""
							CustomValidation=""
							AdditionalCssClasses=""
						/>
						<DropdownMultiple
							ref="languagesSpokenAtHomeField"
							LabelText="Languages Spoken at Home"
							Name="languagesSpokenAtHome"
							:Options="borrowersSpokenLanguages"
							:IsRequired="true"
							HelpText="Hold control (ctrl) to select multiple languages"
						/>
						<Checkbox
							ref="subscribeFormField"
							LabelText="Subscribe to our eNewsletters"
							Name="Subscribe"
							HelpText="Uncheck this option if you do not wish to receive news from your library"
							:IsCheckedByDefault="true"
						/>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-col form-navigation-left">
					<button type="submit" class="btn btn-primary btn-back back-secondary" :disabled="Submitting" @click="back()">
						<span class="las la-chevron-circle-left"></span>
						Previous
					</button>
				</div>
				<div class="form-col form-navigation-right">
					<button type="submit" class="btn btn-primary back-secondary" :disabled="Submitting" @click="submit()">
						Next
						<span class="las la-sign-in-alt"></span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ErrorPanel from '@/components/library-sign-up-form/ErrorPanel.vue';
import borrowersSpokenLanguagesJson from '@/assets/library-signup-form-borrowers-spoken-languages.json';
import Checkbox from '@/components/form-fields/Checkbox.vue';
import DropdownMultiple from '@/components/form-fields/DropdownMultiple.vue';
import TextBox from '@/components/form-fields/TextBox.vue';

export default {
	name: 'Step03ContactDetails',
	components: {
		ErrorPanel,
		TextBox,
		DropdownMultiple,
		Checkbox,
	},
	data() {
		return {
			IsHidden: true,
			HasErrors: false,
			Errors: [],
			Submitting: false,

			borrowersSpokenLanguages: borrowersSpokenLanguagesJson,

			EmailAddress: null,
			PhoneNumber: null,
			LanguagesSpokenAtHome: null,
			SubscribeToNewsletters: false,
		};
	},
	methods: {
		init(debug) {
			var self = this;
			if (debug.enabled) {
				self.$refs.emailAddressFormField.Value = debug.emailAddress;
				self.$refs.phoneNumberFormField.Value = debug.phoneNumber;
				self.$refs.languagesSpokenAtHomeField.Value = debug.languagesSpoken;
			}
		},
		validate() {
			var self = this;

			self.HasErrors = false;
			self.Errors = [];
			self.Submitting = false;

			var formFields = [
				self.$refs.emailAddressFormField,
				self.$refs.phoneNumberFormField,
				self.$refs.languagesSpokenAtHomeField,
			];

			for (var i = 0; i < formFields.length; i++) {
				var validate = formFields[i].validate();
				if (validate.HasErrors) {
					self.HasErrors = true;
				}
			}

			if (self.HasErrors) {
				self.Errors.unshift('Please ensure all fields are correct');
				self.$refs.errorPanel.setErrorMessages(self.Errors);
			} else {
				self.$refs.errorPanel.clearErrors();
			}
		},
		submit() {
			var self = this;

			self.validate();

			if (self.HasErrors) {
				self.$refs.ContactDetails.scrollIntoView({ behavior: 'smooth' });
				return;
			}

			self.EmailAddress = self.$refs.emailAddressFormField.Value;
			self.PhoneNumber = self.$refs.phoneNumberFormField.Value;
			self.LanguagesSpokenAtHome = self.$refs.languagesSpokenAtHomeField.Value;
			self.SubscribeToNewsletters = self.$refs.subscribeFormField.Value;

			self.debug();

			self.$emit('next', {
				EmailAddress: self.EmailAddress,
				PhoneNumber: self.PhoneNumber,
				LanguagesSpokenAtHome: self.LanguagesSpokenAtHome,
				SubscribeToNewsletters: self.SubscribeToNewsletters,
			});
		},
		hide() {
			this.IsHidden = true;
		},
		show() {
			this.IsHidden = false;
		},
		back() {
			this.$emit('back');
		},
		debug() {
			var self = this;
			console.group('Contact Details debug()');
			console.log('EmailAddress: ' + self.EmailAddress);
			console.log('PhoneNumber: ' + self.PhoneNumber);
			console.log('LanguagesSpokenAtHome: ' + self.LanguagesSpokenAtHome);
			console.log('SubscribeToNewsletters: ' + self.SubscribeToNewsletters);
			console.log('HasErrors: ' + self.HasErrors);
			if (self.HasErrors) {
				console.log(self.Errors);
			}
			console.groupEnd();
		},
	},
};
</script>
