<template>
	<div id="ParentGuardianDetails" ref="ParentGuardianDetails" :class="{ 'd-none': IsHidden }">
		<div class="form">
			<ErrorPanel ref="errorPanel" />
			<h2>Parent/Guardian Details</h2>
			<p>Members under the age of 18 require a Parent/Guardian's permission and details to register</p>
			<div class="form-wrapper">
				<div class="form-row">
					<div class="form-full-col form-col">
						<TextBox
							ref="parentGuardianFirstNameFormField"
							LabelText="First Name"
							Type="text"
							Name="parentGuardianFirstName"
							PlaceholderText="First Name"
							:IsRequired="true"
							RequiredMessage=""
							ValidationType=""
							ValidationMessage=""
							CustomValidation=""
							AdditionalCssClasses=""
							InitialValue=""
						/>
						<TextBox
							ref="parentGuardianLastNameFormField"
							LabelText="Last Name"
							Type="text"
							Name="parentGuardianLastName"
							PlaceholderText="Last Name"
							:IsRequired="true"
							RequiredMessage=""
							ValidationType=""
							ValidationMessage=""
							CustomValidation=""
							AdditionalCssClasses=""
							InitialValue=""
						/>
						<TextBox
							ref="parentGuardianPhoneNumberFormField"
							LabelText="Phone Number"
							Type="text"
							Name="parentGuardianPhoneNumber"
							PlaceholderText="Phone Number"
							:IsRequired="false"
							:RunValidationIfFieldHasValue="true"
							RequiredMessage=""
							ValidationType="Phone"
							ValidationMessage=""
							CustomValidation=""
							AdditionalCssClasses=""
							InitialValue=""
						/>
						<TextBox
							ref="parentGuardianEmailAddressFormField"
							LabelText="Email Address"
							Type="text"
							Name="parentGuardianEmailAddress"
							PlaceholderText="Email Address"
							:IsRequired="true"
							RequiredMessage=""
							ValidationType="Email"
							ValidationMessage=""
							CustomValidation=""
							AdditionalCssClasses=""
							InitialValue=""
						/>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-col form-navigation-left">
					<button type="submit" class="btn btn-primary btn-back back-secondary" :disabled="Submitting" @click="back()">
						<span class="las la-chevron-circle-left"></span>
						Previous
					</button>
				</div>
				<div class="form-col form-navigation-right">
					<button type="submit" class="btn btn-primary back-secondary" :disabled="Submitting" @click="submit()">
						Next
						<span class="las la-sign-in-alt"></span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ErrorPanel from '@/components/library-sign-up-form/ErrorPanel.vue';
import TextBox from '@/components/form-fields/TextBox.vue';

export default {
	name: 'Step02ParentGuardianDetails',
	components: {
		ErrorPanel,
		TextBox,
	},
	data() {
		return {
			IsHidden: true,

			ParentGuardianFirstName: null,
			ParentGuardianLastName: null,
			ParentGuardianPhoneNumber: null,
			ParentGuardianEmailAddress: null,

			HasErrors: false,
			Errors: [],
			Submitting: false,
		};
	},
	methods: {
		validate() {
			var self = this;

			self.HasErrors = false;
			self.Errors = [];
			self.Submitting = false;

			var formFields = [
				self.$refs.parentGuardianFirstNameFormField,
				self.$refs.parentGuardianLastNameFormField,
				self.$refs.parentGuardianPhoneNumberFormField,
				self.$refs.parentGuardianEmailAddressFormField,
			];

			for (var i = 0; i < formFields.length; i++) {
				var validate = formFields[i].validate();
				if (validate.HasErrors) {
					self.HasErrors = true;
				}
			}

			if (self.HasErrors) {
				self.Errors.unshift('Please ensure all fields are correct');
				self.$refs.errorPanel.setErrorMessages(self.Errors);
				self.$refs.ParentGuardianDetails.scrollIntoView({ behavior: 'smooth' });
			} else {
				self.$refs.errorPanel.clearErrors();
			}
		},
		back() {
			this.$emit('back');
		},
		submit() {
			var self = this;

			self.validate();

			if (self.HasErrors) {
				self.$refs.ParentGuardianDetails.scrollIntoView({ behavior: 'smooth' });
				return;
			}

			self.ParentGuardianFirstName = self.$refs.parentGuardianFirstNameFormField.Value;
			self.ParentGuardianLastName = self.$refs.parentGuardianLastNameFormField.Value;
			self.ParentGuardianPhoneNumber = self.$refs.parentGuardianPhoneNumberFormField.Value;
			self.ParentGuardianEmailAddress = self.$refs.parentGuardianEmailAddressFormField.Value;

			self.debug();

			self.$emit('next', {
				ParentGuardianFirstName: self.ParentGuardianFirstName,
				ParentGuardianLastName: self.ParentGuardianLastName,
				ParentGuardianPhoneNumber: self.ParentGuardianPhoneNumber,
				ParentGuardianEmailAddress: self.ParentGuardianEmailAddress,
			});
		},
		hide() {
			this.IsHidden = true;
		},
		show() {
			this.IsHidden = false;
		},
		debug() {
			var self = this;

			console.group('Step02ParentGuardianDetails debug()');
			console.log('ParentGuardianFirstName: ' + self.ParentGuardianFirstName);
			console.log('ParentGuardianLastName: ' + self.ParentGuardianLastName);
			console.log('ParentGuardianPhoneNumber: ' + self.ParentGuardianPhoneNumber);
			console.log('ParentGuardianEmailAddress: ' + self.ParentGuardianEmailAddress);

			console.log('HasErrors: ' + self.HasErrors);

			if (self.HasErrors) {
				console.log(self.Errors);
			}

			console.groupEnd();
		},
	},
};
</script>
