<template>
	<div :class="{ 'd-none': IsHidden }">
		<h1>Join YPRL</h1>
		<div class="sign-up-modal-progress-grid">
			<div class="col" :class="{ 'col-active': Step == 1, 'col-complete': Step >= 1 }"><span>Step 1</span></div>
			<div v-show="NumberOfSteps == 6" class="col" :class="{ 'col-active': Step == 2, 'col-complete': Step >= 2 }">
				<span>Step 2</span>
			</div>
			<div class="col" :class="{ 'col-active': Step == 3, 'col-complete': Step >= 3 }"><span>Step 3</span></div>
			<div class="col" :class="{ 'col-active': Step == 4, 'col-complete': Step >= 4 }"><span>Step 4</span></div>
			<div class="col" :class="{ 'col-active': Step == 5, 'col-complete': Step >= 5 }">
				<span>Step 5</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ProgressPanel',
	props: {
		NumberOfSteps: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			IsHidden: false,
			Step: 1,
		};
	},
	methods: {
		setStep(step) {
			this.Step = step;
		},
		show() {
			this.IsHidden = false;
		},
		hide() {
			this.IsHidden = true;
		},
	},
};
</script>
