<template>
	<div>
		<div v-if="hasErrors">
			<ul>
				<li v-for="(error, index) in errors" :key="index">
					{{ error }}
				</li>
			</ul>
		</div>
		<div class="articles-listing">
			<a :href="data.url" class="articles-listing-card" v-for="data in pageData" :key="data.id">
				<div class="articles-listing-back back-primary">
					<!-- article image -->
					<div
						v-if="data.image !== null"
						class="articles-listing-image base-back-image"
						role="img"
						:aria-label="data.image.altText"
						v-bind:style="{ 'background-image': 'url(' + data.image.url + ')' }"
					>
						<div class="articles-listing-line back-secondary"></div>
					</div>
					<div class="articles-listing-content">
						<div>
							<!-- article title -->
							<h3>{{ data.title }}</h3>
							<div class="articles-listing-author" v-if="data.author !== null">
								<div class="articles-listing-author-image" v-if="data.author.image !== null">
									<div class="blog-author-circle circle-sm">
										<div class="blog-author-circle-back back-tertiary">
											<!-- article author image -->
											<div
												class="blog-author-circle-image base-back-image"
												role="img"
												:aria-label="data.author.image.altText"
												v-bind:style="{
													'background-image': 'url(' + data.author.image.url + ')',
												}"
											></div>
										</div>
									</div>
								</div>
								<!-- article author name -->
								<div class="articles-listing-author-name">
									<p>{{ data.author.name }}</p>
								</div>
								<div class="articles-listing-author-seperator back-tertiary"></div>
								<!-- article date -->
								<div class="articles-listing-author-date">
									<p>{{ data.date }}</p>
								</div>
							</div>
							<!-- article summary, textarea -->
							<p>{{ data.description }}</p>
						</div>
						<div class="btn-link-alt colour-tertiary">
							<span class="btn-container">Continue Reading</span>
						</div>
					</div>
				</div>
			</a>
		</div>

		<div class="pagination-container" v-if="totalPages > 1">
			<nav class="pagination back-primary" role="navigation" aria-label="Articles pagination navigation">
				<ul class="no-dots">
					<!-- show if current page is higher than 1 -->
					<li v-if="pageNumber > 1">
						<a
							class="pagination-link h4 pagination-prev colour-secondary"
							@click="getPage(pageNumber - 1)"
							aria-label="Previous"
						></a>
					</li>
					<li v-for="page in pages" :key="page">
						<a
							v-if="page !== pageNumber"
							class="pagination-link h4"
							@click="getPage(page)"
							:aria-label="`Page ${page}`"
						>
							{{ page }}
						</a>
						<a
							v-if="page === pageNumber"
							class="pagination-link h4 colour-tertiary"
							@click="getPage(page)"
							:aria-label="`Current Page, Page ${page}`"
							aria-current="true"
						>
							{{ page }}
						</a>
					</li>
					<!-- if more than 7 items use seperator, 1,2,3,4,5,6,7 is fine without seperator -->
					<li v-if="totalPages > 7 && maxPageNumber !== totalPages" class="pagination-seperator">
						...
					</li>
					<li v-if="maxPageNumber !== totalPages">
						<a class="pagination-link h4" @click="getPage(totalPages)" :aria-label="`Page ${totalPages}`">
							{{ totalPages }}
						</a>
					</li>
					<!-- show if current page is not the last item -->
					<li v-if="pageNumber !== totalPages">
						<a
							class="pagination-link h4 pagination-next colour-secondary"
							@click="getPage(pageNumber + 1)"
							aria-label="Next"
						></a>
					</li>
				</ul>
			</nav>
			<div class="pagination-number">
				<h4>{{ startItemNumber }}-{{ endItemNumber }} of {{ totalItems }}</h4>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
export default {
	props: {
		url: {
			type: String,
			required: true,
		},
		initPageNumber: {
			type: Number,
			required: false,
			default: 1,
		},
		pageSize: {
			type: Number,
			required: false,
			default: 12,
		},
		authorId: {
			type: Number,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			startPageNumber: this.initPageNumber,
			pageNumber: this.initPageNumber,
			pageData: [],
			totalItems: 0,
			totalPages: 0,
			maxPageNumber: 0,
			startItemNumber: 1,
			endItemNumber: this.pageSize,
			pages: [],
			errors: [],
			hasErrors: false,
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			let self = this;

			self.maxPageNumber = self.pageNumber + 7;

			for (let i = self.pageNumber; i < self.maxPageNumber; i++) {
				self.pages.push(i);
			}

			self.getPage(self.startPageNumber, true);
		},
		getPage(page, initRun) {
			let self = this;

			if (!initRun && page && page === self.pageNumber) {
				return;
			}

			Vue.getPageOfBlogArticles(
				self.url,
				self.authorId,
				page !== null ? page : self.pageNumber,
				self.pageSize,
				self.onGetSuccess,
				self.onGetError
			);
		},
		onGetSuccess(response) {
			let self = this;

			self.hasErrors = false;
			self.errors.length = 0;

			self.pageData.length = 0;
			self.pageData.push(...response.data.items);
			self.totalItems = response.data.totalItems;
			self.totalPages = response.data.totalPages;
			self.pageNumber = response.data.pageNumber;

			if (self.pageNumber === 1) {
				self.maxPageNumber = self.pageNumber + 6;
				self.startPageNumber = self.pageNumber;
			} else if (self.pageNumber === 2) {
				self.maxPageNumber = self.pageNumber + 5;
				self.startPageNumber = self.pageNumber - 1;
			} else if (self.pageNumber === 3) {
				self.maxPageNumber = self.pageNumber + 4;
				self.startPageNumber = self.pageNumber - 2;
			} else {
				self.maxPageNumber = self.pageNumber + 3;
				self.startPageNumber = self.pageNumber - 3;
			}

			if (self.maxPageNumber > self.totalPages) {
				self.maxPageNumber = self.totalPages;
			}

			self.startItemNumber = self.pageNumber == 1 ? 1 : (self.pageNumber - 1) * self.pageSize + 1;
			self.endItemNumber = self.pageNumber == 1 ? self.pageSize : self.startItemNumber + self.pageData.length - 1;

			self.pages.length = 0;

			for (let i = self.startPageNumber; i <= self.maxPageNumber; i++) {
				self.pages.push(i);
			}
		},
		onGetError() {
			let self = this;
			self.hasErrors = true;
			self.errors.length = 0;
			self.errors.push('Something went wrong. Please refresh the page and try again');
			self.pages.length = 0;
			self.pageData.length = 0;
			self.pageNumber = self.initPageNumber;
			self.totalItems = 0;
			self.totalPages = 0;
			self.maxPageNumber = 0;
			self.startItemNumber = 0;
			self.endItemNumber = 0;
		},
	},
};
</script>
