<template>
	<div class="field-wrapper" :class="[AdditionalCssClasses, { 'input-error': HasErrors }]">
		<FieldLabel :LabelText="LabelText" :IsRequired="IsRequired" />
		<div v-if="HelpText" class="help-text">{{ HelpText }}</div>
		<select multiple class="select-multiple" v-model="Value" :name="Name" @change="selected($event)">
			<option v-if="PlaceholderText" disabled value="">{{ PlaceholderText }}</option>
			<option v-for="option in Options" :value="option.Value" :key="option.Value">
				{{ option.Text }}
			</option>
		</select>
		<span class="field-validation" v-show="HasErrors">
			<span v-for="error in Errors" :key="error">
				{{ error }}
			</span>
		</span>
	</div>
</template>
<style type="text/css">
.form .field-wrapper select.select-multiple {
	background-image: none;
	height: 197px;
}
/* i have no idea why the first item in a select list would be set to the same colour as the background of the select?! */
.form .field-wrapper select.select-multiple option:first-child {
	color: #5b5766;
	background-image: none;
	font-size: 18px;
}
</style>
<script>
import Vue from 'vue';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';

export default {
	name: 'Dropdown',
	props: {
		LabelText: {
			type: String,
			required: true,
		},
		Name: {
			type: String,
			required: true,
		},
		PlaceholderText: {
			type: String,
			required: false,
		},
		Options: {
			type: Array,
			required: true,
		},
		IsRequired: {
			type: Boolean,
			default: false,
		},
		RequiredMessage: {
			type: String,
			required: false,
		},
		AdditionalCssClasses: {
			type: String,
			required: false,
		},
		HelpText: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			FieldType: 'dropdown',
			Value: [],
			HasErrors: false,
			Errors: [],
		};
	},
	mounted() {
		var self = this;
		for (var i = 0; i < self.Options.length; i++) {
			var option = self.Options[i];
			if (option.Selected) {
				option.Selected = true;
				self.Value.push(option.Value);
			}
		}
	},
	methods: {
		selected(event) {
			this.setValue(event.target.value);
		},
		setValue(value) {
			var self = this;
			try {
				//as this method can be called from other components we need to make sure the passed value is actually in the options
				for (var i = 0; i < self.Options.length; i++) {
					var option = self.Options[i];
					if (self.Options[i].Value === value) {
						option.Selected = true;
						if (!self.Value.includes(option.Value)) {
							self.Value.push(option.Value);
						}
					}
				}
			} finally {
				self.validate();
			}
		},
		validate() {
			var self = this;

			var validation = Vue.validateFormFieldValue(self.Value, self.IsRequired, self.RequiredMessage);

			self.HasErrors = validation.HasErrors;
			self.Errors = validation.Errors;

			return validation;
		},
		clearErrors() {
			var self = this;

			self.HasErrors = false;
			self.Errors = [];
		},
	},
	components: {
		FieldLabel,
	},
};
</script>
