<script>
import axios from 'axios';

export default {
	install(Vue) {
		var self = this;
		self.baseUrl = '/umbraco/api/';
		self.baseFormApiUrl = self.baseUrl + 'LibrarySignUpForm/';

		self.searchForExistingBorrowerEndpointUrl = self.baseFormApiUrl + 'SearchForExistingBorrower';
		self.processFormSubmissionEndpointUrl = self.baseFormApiUrl + 'ProcessFormSubmission';

		Vue.searchForExistingBorrower = function(params, successCallback, errorCallback, finallyCallback) {
			var url = `${self.searchForExistingBorrowerEndpointUrl}?FirstName=${params.firstName}&LastName=${params.lastName}&DateOfBirth=${params.dateOfBirth}`;

			console.log(url);

			Vue.httpGet(url, successCallback, errorCallback, finallyCallback);
		};

		Vue.ProcessFormSubmission = function(params, successCallback, errorCallback, finallyCallback) {
			Vue.httpPost(self.processFormSubmissionEndpointUrl, params, successCallback, errorCallback, finallyCallback);
		};

		Vue.httpGet = function(url, successCallback, errorCallback, finallyCallback) {
			if (successCallback) {
				if (errorCallback) {
					if (finallyCallback) {
						axios
							.get(url)
							.then(successCallback)
							.catch(errorCallback)
							.finally(finallyCallback);
					} else {
						axios
							.get(url)
							.then(successCallback)
							.catch(errorCallback);
					}
				} else {
					if (finallyCallback) {
						axios
							.get(url)
							.then(successCallback)
							.finally(finallyCallback);
					} else {
						axios.get(url).then(successCallback);
					}
				}
			} else if (errorCallback) {
				if (finallyCallback) {
					axios
						.get(url)
						.catch(errorCallback)
						.finally(finallyCallback);
				} else {
					axios.get(url).catch(errorCallback);
				}
			} else {
				if (finallyCallback) {
					axios.get(url).finally(finallyCallback);
				} else {
					axios.get(url);
				}
			}
		};

		Vue.httpPost = function(url, postData, successCallback, errorCallback, finallyCallback) {
			if (successCallback) {
				if (errorCallback) {
					if (finallyCallback) {
						axios
							.post(url, postData)
							.then(successCallback)
							.catch(errorCallback)
							.finally(finallyCallback);
					} else {
						axios
							.post(url, postData)
							.then(successCallback)
							.catch(errorCallback);
					}
				} else {
					if (finallyCallback) {
						axios
							.post(url, postData)
							.then(successCallback)
							.finally(finallyCallback);
					} else {
						axios.post(url, postData).then(successCallback);
					}
				}
			} else if (errorCallback) {
				if (finallyCallback) {
					axios
						.post(url, postData)
						.catch(errorCallback)
						.finally(finallyCallback);
				} else {
					axios.post(url, postData).catch(errorCallback);
				}
			} else {
				if (finallyCallback) {
					axios.post(url, postData).finally(finallyCallback);
				} else {
					axios.post(url, postData);
				}
			}
		};
	},
};
</script>
