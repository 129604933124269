<template>
	<div class="field-wrapper" :class="[AdditionalCssClasses, { 'input-error': HasErrors }]">
		<FieldLabel :LabelText="LabelText" :IsRequired="IsRequired" />
		<select v-model="Value" :name="Name" @change="selected($event)">
			<option v-if="PlaceholderText" disabled value="">{{ PlaceholderText }}</option>
			<option v-for="option in Options" :value="option.Value" :key="option.Value">
				{{ option.Text }}
			</option>
		</select>
		<span class="field-validation" v-show="HasErrors">
			<span v-for="error in Errors" :key="error">
				{{ error }}
			</span>
		</span>
	</div>
</template>
<script>
import Vue from 'vue';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';

export default {
	name: 'Dropdown',
	props: {
		LabelText: {
			type: String,
			required: true,
		},
		Name: {
			type: String,
			required: true,
		},
		PlaceholderText: {
			type: String,
			required: false,
		},
		Options: {
			type: Array,
			required: true,
		},
		IsDefaultSelected: {
			type: Boolean,
			default: false,
		},
		IsRequired: {
			type: Boolean,
			default: false,
		},
		RequiredMessage: {
			type: String,
			required: false,
		},
		AdditionalCssClasses: {
			type: String,
			required: false,
		},
	},
	mounted() {
		for (var i = 0; i < this.Options.length; i++) {
			var option = this.Options[i];
			if (option.IsDefaultSelected) {
				option.Selected = true;
				this.Value = option.Value;
				break;
			}
		}
	},
	data() {
		return {
			FieldType: 'dropdown',
			Value: null,
			HasErrors: false,
			Errors: [],
		};
	},
	methods: {
		selected(event) {
			this.setValue(event.target.value);
		},
		setValue(value) {
			var self = this;

			try {
				var option = null;

				for (var i = 0; i < self.Options.length; i++) {
					if (self.Options[i].Value === value) {
						option = self.Options[i];
						self.Value = self.Options[i].Value;
						continue;
					}
					self.Options[i].Selected = false;
				}

				if (!option) {
					self.Value = null;
					return;
				}

				option.Selected = true;
				self.Value = option.Value;
			} finally {
				self.$emit('change', self.Value);
				self.validate();
			}
		},
		validate() {
			var validation = Vue.validateFormFieldValue(this.Value, this.IsRequired, this.RequiredMessage);

			this.HasErrors = validation.HasErrors;
			this.Errors = validation.Errors;

			return validation;
		},
		clearErrors() {
			this.HasErrors = false;
			this.Errors = [];
		},
	},
	components: {
		FieldLabel,
	},
};
</script>
