<template>
	<div class="field-wrapper" :class="{ 'input-error': HasErrors }">
		<FieldLabel :LabelText="LabelText" :IsRequired="IsRequired" />
		<div class="help-text">
			Please use D/M/YYYY format
		</div>
		<div>
			<Vue2DatePicker
				ref="vue2DatePicker"
				v-model="Value"
				@open="open"
				@pick="change"
				@close="change"
				@input-error="change"
				@blur="change"
				:editable="true"
				:clearable="false"
				input-class="text "
				format="D/M/YYYY"
				:default-value="defaultValue"
				:placeholder="PlaceholderText"
				:disabled-date="disabledAfterToday"
				:disabled-calendar-changer="disabledCalendarChanger"
				:input-attr="inputAttr"
			/>
		</div>
		<span class="field-validation" v-show="HasErrors">
			<span v-for="error in Errors" :key="error">
				{{ error }}
			</span>
		</span>
	</div>
</template>
<style type="text/css">
/* the site styles are messing with the date picker styles... */
.mx-btn:disabled,
.mx-btn.disabled {
	color: #ccc !important;
	background-color: transparent !important;
}

.mx-btn:disabled:hover,
.mx-btn.disabled:hover {
	color: #ccc !important;
	background-color: transparent !important;
}
</style>
<script>
import Vue from 'vue';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';
import Vue2DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/en';

export default {
	name: 'TextBox',
	props: {
		LabelText: {
			type: String,
			required: true,
		},
		Name: {
			type: String,
			required: true,
		},
		PlaceholderText: {
			type: String,
			required: false,
		},
		IsRequired: {
			type: Boolean,
			default: false,
		},
		RequiredMessage: {
			type: String,
			required: false,
		},
		ValidationType: {
			type: String,
			required: false,
		},
		ValidationMessage: {
			type: String,
			required: false,
		},
		CustomValidation: {
			type: String,
			required: false,
		},
		AdditionalCssClasses: {
			type: String,
			required: false,
		},
		MaxLength: {
			type: Number,
			required: false,
		},
		InitialValue: {
			type: Date,
			required: false,
		},
	},
	data() {
		return {
			FieldType: 'datePickertextBox',
			Value: null,
			HasErrors: false,
			Errors: [],
			defaultValue: null,
			inputAttr: { id: 'librarySignUpFormDobInput' },
		};
	},
	mounted() {
		var self = this;
		self.Value = self.InitialValue;
	},
	methods: {
		open() {
			var self = this;
			if (!self.Value || self.Value === null || self.Value.length <= 0) {
				self.Value = new Date();
			}

			const librarySignUpFormDobInput = document.getElementById('librarySignUpFormDobInput');
			librarySignUpFormDobInput.focus();
			librarySignUpFormDobInput.select();
		},
		change() {
			var self = this;

			const librarySignUpFormDobInput = document.getElementById('librarySignUpFormDobInput');
			librarySignUpFormDobInput.setSelectionRange(0, 0);

			if (self.Value === null || self.Value.length <= 0) {
				return;
			}

			self.Value.setHours(0, 0, 0, 0);

			self.$emit('change', self.Value);
			self.validate();
		},
		validate() {
			var self = this;

			var validation = Vue.validateFormTextFieldValue(
				self.Value,
				self.IsRequired,
				self.RequiredMessage,
				self.ValidationType,
				self.ValidationMessage,
				self.CustomValidation
			);

			self.HasErrors = validation.HasErrors;
			self.Errors = validation.Errors;

			return validation;
		},
		clearErrors() {
			var self = this;
			self.HasErrors = false;
			self.Errors = [];
		},
		disabledAfterToday(date) {
			const today = new Date();
			today.setHours(0, 0, 0, 0);

			const oneHundredAndTenYearsAgo = new Date();
			oneHundredAndTenYearsAgo.setFullYear(oneHundredAndTenYearsAgo.getFullYear() - 110);

			return date > today || date < oneHundredAndTenYearsAgo;
		},
		disabledCalendarChanger(date, type) {
			if (type === 'year' || type === 'month') {
				return false;
			}
			return this.disabledAfterToday(date);
		},
	},
	components: {
		FieldLabel,
		Vue2DatePicker,
	},
};
</script>
