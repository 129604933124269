<template>
	<div>
		<label class="form-label">
			{{ LabelText }}
			<span v-if="IsRequired" class="required" aria-required="true">*</span>
		</label>
	</div>
</template>
<script>
export default {
	name: 'FieldLabel',
	props: {
		LabelText: {
			type: String,
			required: true,
		},
		IsRequired: {
			type: Boolean,
		},
	},
};
</script>
