<template>
	<div>
		<div v-if="hasErrors">
			<ul>
				<li v-for="(error, index) in errors" :key="index">
					{{ error }}
				</li>
			</ul>
		</div>
		<section class="blog-landing-filter">
			<div class="blog-landing-filter-heading">
				<h1>{{ title }}</h1>
				<div class="blog-landing-filter-squiggle">
					<svg
						width="328"
						height="36"
						viewBox="0 0 308 36"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							class="stroke-secondary"
							d="M3 27.25L16.9986 14.6396C25.882 6.63727 39.6296 7.59221 47.3212 16.7459L48.375 18C56.2697 27.3954 70.7303 27.3954 78.625 18V18C86.5197 8.60461 100.98 8.60461 108.875 18V18C116.77 27.3954 131.23 27.3954 139.125 18V18C147.02 8.60461 161.48 8.60461 169.375 18V18C177.27 27.3954 191.761 27.3584 199.656 17.963V17.963C207.541 8.57886 222.064 8.56266 230.025 17.8827V17.8827C238.078 27.3112 252.748 27.3023 260.686 17.7766L261.453 16.856C269.112 7.66529 282.886 6.70884 291.742 14.7527L305.5 27.25"
							stroke-width="15"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
			<div class="blog-landing-filter-wrapper">
				<div class="blog-landing-filter-toggle">
					<button class="h4" aria-label="Open filter">Filter</button>
				</div>
				<div class="blog-landing-filter-content">
					<div class="blog-landing-filter-terms">
						<button
							class="blog-landing-filter-term colour-tertiary"
							v-for="keyword in keywords"
							:key="keyword"
							:aria-label="'Remove ' + keyword"
							@click="removeKeyword(keyword)"
						>
							<span
								class="blog-landing-filter-term-icon las la-times colour-secondary"
							></span>
							{{ keyword }}
						</button>
					</div>
					<div class="blog-landing-filter-inputs">
						<!-- toggle open class  -->
						<div class="blog-landing-filter-date blog-landing-filter-input">
							<div
								class="blog-landing-filter-date-dropdown blog-landing-filter-input-dropdown"
							>
								<span>Filter by Date</span>
								<div
									class="blog-landing-filter-date-arrow blog-landing-filter-input-arrow custom-icon i-arrow-sm"
									aria-label="Open dates list"
									role="button"
									aria-pressed="false"
									tabindex="0"
								></div>
							</div>
							<div
								class="blog-landing-filter-date-menu-container blog-landing-filter-input-menu-container"
								aria-hidden="true"
							>
								<span
									class="blog-landing-filter-date-triangle blog-landing-filter-input-triangle custom-icon i-menu-triangle"
								></span>
								<div
									class="blog-landing-filter-input-head blog-landing-filter-date-head"
								>
									<button
										class="blog-landing-filter-input-close"
										aria-label="Close filter"
									>
										<span class="las la-times colour-secondary"></span>
									</button>
									<h4 class="blog-landing-filter-input-title colour-tertiary">
										Filter by date
									</h4>
								</div>
								<div
									class="blog-landing-filter-date-menu blog-landing-filter-input-menu"
								>
									<div
										class="blog-landing-filter-date-col"
										v-for="year in dates"
										:key="year.key"
									>
										<p class="blog-landing-filter-date-title">
											{{ year.year }}
										</p>
										<ul class="blog-landing-filter-date-items no-dots">
											<li
												class="blog-landing-filter-date-item"
												v-for="month in year.months"
												:key="month.key"
												:class="{
													selected:
														(startDate &&
															startDate.year === year.year &&
															startDate.month === year.month) ||
														(endDate &&
															endDate.year === year.year &&
															endDate.month === year.month),
													'not-active': !month.hasContent,
												}"
											>
												<template v-if="month.hasContent">
													<button @click="dateSelected(month)">
														{{ month.monthName }}
													</button>
												</template>
												<template v-if="!month.hasContent">
													{{ month.monthName }}
												</template>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<!-- toggle open class  -->
						<div class="blog-landing-filter-tags blog-landing-filter-input">
							<div
								class="blog-landing-filter-tags-dropdown blog-landing-filter-input-dropdown"
							>
								<span>Filter by Categories</span>
								<div
									class="blog-landing-filter-tags-arrow blog-landing-filter-input-arrow custom-icon i-arrow-sm"
									aria-label="Open translation languages list"
									role="button"
									aria-pressed="false"
									tabindex="0"
								></div>
							</div>
							<div
								class="blog-landing-filter-tags-menu-container blog-landing-filter-input-menu-container"
								aria-hidden="true"
							>
								<span
									class="blog-landing-filter-tags-triangle blog-landing-filter-input-triangle custom-icon i-menu-triangle"
								></span>
								<div
									class="blog-landing-filter-input-head blog-landing-filter-tags-head"
								>
									<button
										class="blog-landing-filter-input-close"
										aria-label="Close filter"
									>
										<span class="las la-times colour-secondary"></span>
									</button>
									<h4 class="blog-landing-filter-input-title colour-tertiary">
										Filter by Categories
									</h4>
								</div>
								<div
									class="blog-landing-filter-tags-menu blog-landing-filter-input-menu"
								>
									<div class="blog-landing-filter-tags-col">
										<ul class="blog-landing-filter-tags-items no-dots">
											<li
												class="blog-landing-filter-tags-item"
												v-for="tag in tags"
												:key="tag"
												:class="{
													selected:
														selectedTags && selectedTags.indexOf(tag) >= 0,
												}"
											>
												<button @click="tagSelected(tag)">{{ tag }}</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<!-- toggle open class -->
						<div class="blog-landing-filter-age blog-landing-filter-input">
							<div
								class="blog-landing-filter-age-dropdown blog-landing-filter-input-dropdown"
							>
								<span>Filter by Age Group</span>
								<div
									class="blog-landing-filter-age-arrow blog-landing-filter-input-arrow custom-icon i-arrow-sm"
									aria-label="Open translation languages list"
									role="button"
									aria-pressed="false"
									tabindex="0"
								></div>
							</div>
							<div
								class="blog-landing-filter-age-menu-container blog-landing-filter-input-menu-container"
								aria-hidden="true"
							>
								<span
									class="blog-landing-filter-age-triangle blog-landing-filter-input-triangle custom-icon i-menu-triangle"
								></span>
								<div
									class="blog-landing-filter-input-head blog-landing-filter-age-head"
								>
									<button
										class="blog-landing-filter-input-close"
										aria-label="Close filter"
									>
										<span class="las la-times colour-secondary"></span>
									</button>
									<h4 class="blog-landing-filter-input-title colour-tertiary">
										Filter by Age Group
									</h4>
								</div>
								<div
									class="blog-landing-filter-age-menu blog-landing-filter-input-menu"
								>
									<div class="blog-landing-filter-age-col">
										<ul class="blog-landing-filter-age-items no-dots">
											<li
												class="blog-landing-filter-age-item"
												v-for="ageGroup in ageGroups"
												:key="ageGroup.ageGroup"
												:class="{
													selected:
														selectedAgeGroups.indexOf(ageGroup.ageGroup) >= 0,
												}"
											>
												<button @click="ageGroupSelected(ageGroup.ageGroup)">
													{{ ageGroup.ageGroup }} ({{ ageGroup.count }})
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="blog-landing-filter-keyword">
							<div class="blog-landing-filter-keyword-container">
								<input
									class="blog-landing-filter-keyword-input"
									v-model="keyword"
									type="text"
									placeholder="Filter by Keyword"
									@keyup.enter="addKeyword"
								/>
								<button
									class="blog-landing-filter-keyword-button"
									aria-label="Add Keyowrd to filter"
									@click="addKeyword"
								></button>
							</div>
						</div>
					</div>
					<div class="blog-landing-filter-clear">
						<button
							class="blog-landing-filter-clear-button colour-tertiary"
							aria-label="Clear All Filters"
							@click="clearFilters"
						>
							<span
								class="blog-landing-filter-clear-icon las la-times colour-secondary"
							></span>
							Clear All Filters
						</button>
					</div>
				</div>
			</div>
		</section>

		<section class="featured-article" v-if="featuredArticle !== null">
			<div class="featured-article-content">
				<div class="featured-article-content-container">
					<h2 class="featured-article-title">{{ featuredArticle.title }}</h2>
					<div
						class="featured-article-details"
						v-if="featuredArticle.author !== null"
					>
						<a
							class="featured-article-author"
							:href="featuredArticle.author.url"
						>
							<div
								class="featured-article-author-image"
								v-if="featuredArticle.author.image !== null"
							>
								<div class="blog-author-circle circle-sm">
									<div class="blog-author-circle-back back-tertiary">
										<div
											class="blog-author-circle-image base-back-image"
											role="img"
											aria-label="{{featuredArticle.author.image.altText}}"
											v-bind:style="{
												'background-image':
													'url(' + featuredArticle.author.image.url + ')',
											}"
										></div>
									</div>
								</div>
							</div>

							<div class="featured-article-author-name">
								<p>{{ featuredArticle.author.name }}</p>
							</div>
						</a>
						<div class="featured-article-details-seperator back-tertiary"></div>
						<div class="featured-article-details-date">
							<p>{{ featuredArticle.date }}</p>
						</div>
					</div>

					<div class="featured-article-text">
						<p>
							{{ featuredArticle.description }}
						</p>
					</div>
					<a :href="featuredArticle.url" class="btn-link-alt colour-tertiary"
						><span class="btn-container">Continue Reading</span></a
					>
				</div>
			</div>
			<div
				class="featured-article-image-section"
				v-if="featuredArticle.image !== null"
			>
				<div class="featured-article-image-container">
					<div
						class="featured-article-image base-back-image"
						role="img"
						aria-label="{{featuredArticle.image.altText}}"
						v-bind:style="{
							'background-image': 'url(' + featuredArticle.image.url + ')',
						}"
					></div>
					<div class="featured-article-lines">
						<svg viewBox="0 0 1700 60" xmlns="http://www.w3.org/2000/svg">
							<defs>
								<pattern
									id="featuredArticleLines"
									x="0"
									y="0"
									width="700"
									height="60"
									patternUnits="userSpaceOnUse"
								>
									<rect
										y="0.453491"
										width="838"
										height="10"
										class="fill-tertiary"
									/>
									<rect
										y="25.4535"
										width="838"
										height="10"
										class="fill-tertiary"
									/>
									<rect
										y="50.4535"
										width="838"
										height="10"
										class="fill-tertiary"
									/>
								</pattern>
							</defs>
							<rect
								fill="url(#featuredArticleLines)"
								width="100%"
								height="100%"
							/>
						</svg>
					</div>
					<div class="featured-article-shape">
						<svg
							width="222"
							height="222"
							viewBox="0 0 222 222"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<mask
								id="featureArticleMask"
								mask-type="alpha"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="222"
								height="222"
							>
								<rect width="222" height="222" fill="#C4C4C4" />
							</mask>
							<g mask="url(#featureArticleMask)">
								<path
									d="M229.5 29V10.8934L216.697 23.6967L23.6967 216.697L10.8934 229.5H29H222H229.5V222V29Z"
									fill="#ED5F87"
									stroke="white"
									stroke-width="15"
								/>
								<path d="M222 222V29L29 222H222Z" fill="#ED5F87" />
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M237 -7.21289V237H-7.21338L237 -7.21289ZM222 29.0003L28.9998 222H222V29.0003Z"
									fill="white"
								/>
							</g>
						</svg>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="articles-listing">
				<a
					:href="data.url"
					class="articles-listing-card"
					v-for="data in pageData"
					:key="data.id"
				>
					<div class="articles-listing-back back-primary">
						<!-- article image -->
						<div
							v-if="data.image !== null"
							class="articles-listing-image base-back-image"
							role="img"
							:aria-label="data.image.altText"
							v-bind:style="{
								'background-image': 'url(' + data.image.url + ')',
							}"
						>
							<div class="articles-listing-line back-secondary"></div>
						</div>
						<div class="articles-listing-content">
							<div>
								<!-- article title -->
								<h3>{{ data.title }}</h3>
								<div
									class="articles-listing-author"
									v-if="data.author !== null"
								>
									<div
										class="articles-listing-author-image"
										v-if="data.author.image !== null"
									>
										<div class="blog-author-circle circle-sm">
											<div class="blog-author-circle-back back-tertiary">
												<!-- article author image -->
												<div
													class="blog-author-circle-image base-back-image"
													role="img"
													aria-label="{{data.author.image.altText}}"
													v-bind:style="{
														'background-image':
															'url(' + data.author.image.url + ')',
													}"
												></div>
											</div>
										</div>
									</div>
									<!-- article author name -->
									<div class="articles-listing-author-name">
										<p>{{ data.author.name }}</p>
									</div>
									<div
										class="articles-listing-author-seperator back-tertiary"
									></div>
									<!-- article date -->
									<div class="articles-listing-author-date">
										<p>{{ data.date }}</p>
									</div>
								</div>
								<!-- article summary, textarea -->
								<p>{{ data.description }}</p>
							</div>
							<div class="btn-link-alt colour-tertiary">
								<span class="btn-container">Continue Reading</span>
							</div>
						</div>
					</div>
				</a>
			</div>

			<div class="pagination-container" v-if="totalPages > 1">
				<nav
					class="pagination back-primary"
					role="navigation"
					aria-label="Articles pagination navigation"
				>
					<ul class="no-dots">
						<!-- show if current page is higher than 1 -->
						<li v-if="pageNumber > 1">
							<a
								class="pagination-link h4 pagination-prev colour-secondary"
								@click="getPage(pageNumber - 1)"
								aria-label="Previous"
							></a>
						</li>
						<li v-for="page in pages" :key="page">
							<a
								v-if="page !== pageNumber"
								class="pagination-link h4"
								@click="getPage(page)"
								aria-label="Page {{page}}"
								>{{ page }}</a
							>
							<a
								v-if="page === pageNumber"
								class="pagination-link h4 colour-tertiary"
								@click="getPage(page)"
								aria-label="Current Page, Page {{page}}"
								aria-current="true"
								>{{ page }}</a
							>
						</li>
						<!-- if more than 7 items use seperator, 1,2,3,4,5,6,7 is fine without seperator -->
						<li
							v-if="totalPages > 7 && maxPageNumber !== totalPages"
							class="pagination-seperator"
						>
							...
						</li>
						<li v-if="maxPageNumber !== totalPages">
							<a
								class="pagination-link h4"
								@click="getPage(totalPages)"
								aria-label="Page {{totalPages}}"
								>{{ totalPages }}</a
							>
						</li>
						<!-- show if current page is not the last item -->
						<li v-if="pageNumber !== totalPages">
							<a
								class="pagination-link h4 pagination-next colour-secondary"
								@click="getPage(pageNumber + 1)"
								aria-label="Next"
							></a>
						</li>
					</ul>
				</nav>
				<div class="pagination-number">
					<h4>{{ startItemNumber }}-{{ endItemNumber }} of {{ totalItems }}</h4>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Vue from "vue";
export default {
	props: {
		blogPageTitle: {
			type: String,
			required: true,
		},
		blogPageId: {
			type: Number,
			required: true,
		},
		url: {
			type: String,
			required: true,
		},
		getFeaturedArticleUrl: {
			type: String,
			required: true,
		},
		getBlogArticleTagsUrl: {
			type: String,
			required: true,
		},
		getBlogArticleDatesUrl: {
			type: String,
			required: true,
		},
		getBlogArticleAgeGroupsUrl: {
			type: String,
			required: true,
		},
		initPageNumber: {
			type: Number,
			required: false,
			default: 1,
		},
		pageSize: {
			type: Number,
			required: false,
			default: 12,
		},
		featuredArticleId: {
			type: Number,
			required: false,
		},
		initialTags: {
			type: Array,
			required: false,
		},
	},
	data() {
		return {
			startPageNumber: this.initPageNumber,
			pageNumber: this.initPageNumber,
			pageData: [],
			totalItems: 0,
			totalPages: 0,
			maxPageNumber: 0,
			startItemNumber: 1,
			endItemNumber: this.pageSize,
			pages: [],
			errors: [],
			hasErrors: false,
			featuredArticle: null,
			dates: [],
			startDate: null,
			endDate: null,
			tags: [],
			selectedTags: [],
			ageGroups: [],
			selectedAgeGroups: [],
			keyword: null,
			keywords: [],
			title: this.blogPageTitle,
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			let self = this;

			self.maxPageNumber = self.pageNumber + 7;

			for (let i = self.pageNumber; i < self.maxPageNumber; i++) {
				self.pages.push(i);
			}

			if (self.initialTags && self.initialTags.length > 0) {
				for (let i = 0; i < self.initialTags.length; i++) {
					self.selectedTags.push(self.initialTags[i]);
				}
			}

			self.getBlogArticleDates();
			self.getBlogArticleTags();
			self.getBlogArticleAgeGroups();
			if (self.featuredArticleId) {
				self.getFeaturedArticle();
			} else {
				self.getPage(self.startPageNumber, true);
			}
		},
		getBlogArticleDates() {
			let self = this;

			Vue.getDataFromUrl(
				self.getBlogArticleDatesUrl,
				self.onGetBlogArticleDatesSuccess,
				self.onGetError
			);
		},
		getBlogArticleTags() {
			let self = this;

			Vue.getDataFromUrl(
				self.getBlogArticleTagsUrl,
				self.onGetBlogArticleTagsSuccess,
				self.onGetError
			);
		},
		getBlogArticleAgeGroups() {
			let self = this;

			Vue.getDataFromUrl(
				self.getBlogArticleAgeGroupsUrl,
				self.onGetBlogArticleAgeGroupsSuccess,
				self.onGetError
			);
		},
		getFeaturedArticle() {
			let self = this;

			Vue.getFeaturedArticle(
				self.getFeaturedArticleUrl,
				self.featuredArticleId,
				self.onGetFeaturedArticleSuccess,
				self.onGetError
			);
		},
		getPage(page, initRun) {
			let self = this;

			if (!initRun && page && page === self.pageNumber) {
				return;
			}
			Vue.getFilteredPageOfBlogArticles(
				self.url,
				self.startDate,
				self.endDate,
				self.selectedTags,
				self.selectedAgeGroups,
				self.keywords,
				page !== null ? page : self.pageNumber,
				self.blogPageId,
				self.pageSize,
				self.onGetPageSuccess,
				self.onGetError
			);
		},
		addKeyword() {
			let self = this;
			self.keywords.push(self.keyword);
			self.keyword = null;

			self.pageNumber = 1;
			self.getPage(null);
		},
		dateSelected(date) {
			let self = this;

			if (!self.startDate) {
				self.startDate = date;
				self.endDate = null;
				self.pageNumber = 1;
				self.getPage(null);
				return;
			}

			if (
				self.startDate &&
				self.startDate.month === date.month &&
				self.startDate.year === date.year
			) {
				self.startDate = null;
				self.startDate = self.endDate;
				self.endDate = null;
				self.pageNumber = 1;
				self.getPage(null);
				return;
			}

			if (
				self.endDate &&
				self.endDate.month === date.month &&
				self.endDate.year === date.year
			) {
				self.endDate = null;
				self.pageNumber = 1;
				self.getPage(null);
				return;
			}

			if (date.year === self.startDate.year) {
				if (date.month < self.startDate.month) {
					self.endDate = self.startDate;
					self.startDate = date;
				} else {
					self.endDate = date;
				}
			} else if (date.year < self.startDate.year) {
				self.endDate = self.startDate;
				self.startDate = date;
			} else {
				self.endDate = date;
			}

			self.pageNumber = 1;
			self.getPage(null);
		},
		tagSelected(tagName) {
			let self = this;
			let tagIndex = self.selectedTags.indexOf(tagName);

			if (tagIndex >= 0) {
				self.selectedTags.splice(tagIndex);
			} else {
				self.selectedTags.push(tagName);
			}

			self.pageNumber = 1;
			self.getPage(null);
		},
		ageGroupSelected(ageGroup) {
			let self = this;
			let ageGroupIndex = self.selectedAgeGroups.indexOf(ageGroup);

			if (ageGroupIndex >= 0) {
				self.selectedAgeGroups.splice(ageGroupIndex);
			} else {
				self.selectedAgeGroups.push(ageGroup);
			}

			self.pageNumber = 1;
			self.getPage(null);
		},
		viewAllTagsClicked() {
			let self = this;

			if (self.selectedTags.length == self.tags.length) {
				self.selectedTags.length = 0;
			} else {
				self.selectedTags.length = 0;
				self.tags.forEach((tag) => self.selectedTags.push(tag.tag));
			}

			self.pageNumber = 1;
			self.getPage(null);
		},
		onGetBlogArticleDatesSuccess(response) {
			if (!response || !response.data) {
				return;
			}

			let self = this;

			for (let i = 0; i < response.data.length; i++) {
				self.dates.push(response.data[i]);
			}
		},
		onGetBlogArticleTagsSuccess(response) {
			if (!response || !response.data) {
				return;
			}

			let self = this;

			for (let i = 0; i < response.data.length; i++) {
				self.tags.push(response.data[i]);
			}
		},
		onGetBlogArticleAgeGroupsSuccess(response) {
			if (!response || !response.data) {
				return;
			}

			let self = this;

			for (let i = 0; i < response.data.length; i++) {
				self.ageGroups.push(response.data[i]);
			}
		},
		onGetFeaturedArticleSuccess(response) {
			let self = this;

			self.featuredArticle = response.data;

			self.getPage(self.startPageNumber, true);
		},
		onGetPageSuccess(response) {
			let self = this;

			self.hasErrors = false;
			self.errors.length = 0;

			self.pageData.length = 0;
			self.pageData.push(...response.data.items);
			self.totalItems = response.data.totalItems;
			self.totalPages = response.data.totalPages;
			self.pageNumber = response.data.pageNumber;

			if (self.pageNumber === 1) {
				self.maxPageNumber = self.pageNumber + 6;
				self.startPageNumber = self.pageNumber;
			} else if (self.pageNumber === 2) {
				self.maxPageNumber = self.pageNumber + 5;
				self.startPageNumber = self.pageNumber - 1;
			} else if (self.pageNumber === 3) {
				self.maxPageNumber = self.pageNumber + 4;
				self.startPageNumber = self.pageNumber - 2;
			} else {
				self.maxPageNumber = self.pageNumber + 3;
				self.startPageNumber = self.pageNumber - 3;
			}

			if (self.maxPageNumber > self.totalPages) {
				self.maxPageNumber = self.totalPages;
			}

			self.startItemNumber =
				self.pageNumber == 1 ? 1 : (self.pageNumber - 1) * self.pageSize + 1;
			self.endItemNumber =
				self.pageNumber == 1
					? self.pageSize
					: self.startItemNumber + self.pageData.length - 1;

			self.pages.length = 0;

			for (let i = self.startPageNumber; i <= self.maxPageNumber; i++) {
				self.pages.push(i);
			}
		},
		removeKeyword(keyword) {
			if (!keyword || keyword.length <= 0) {
				return;
			}

			let self = this;

			let keywordIndex = self.keywords.indexOf(keyword);

			if (keywordIndex < 0) {
				return;
			}

			self.keywords.splice(keywordIndex);

			self.pageNumber = 1;
			self.getPage(null);
		},
		clearFilters() {
			let self = this;
			self.startDate = null;
			self.endDate = null;
			self.keyword = null;
			self.keywords.length = 0;
			self.selectedTags.length = 0;
			self.selectedAgeGroups.length = 0;

			self.pageNumber = 1;
			self.getPage(null);
		},
		onGetError() {
			let self = this;
			self.hasErrors = true;
			self.errors.length = 0;
			self.errors.push(
				"Something went wrong. Please refresh the page and try again"
			);
			self.pages.length = 0;
			self.pageData.length = 0;
			self.pageNumber = self.initPageNumber;
			self.totalItems = 0;
			self.totalPages = 0;
			self.maxPageNumber = 0;
			self.startItemNumber = 0;
			self.endItemNumber = 0;
		},
	},
};
</script>
