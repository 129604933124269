<template>
	<div class="locations-modal-locations-list-item">
		<div class="locations-modal-locations-list-text">
			<p class="locations-modal-locations-list-title">{{ location.title }}</p>

			<div class="locations-modal-locations-list-times" v-if="!openAllDay">
				<template v-for="(period, index) in openingHours">
					<p :key="period.openingTime">{{ period.openingTime }} – {{ period.closingTime }}</p>
					<span
						:key="period.closingTime"
						v-if="index < openingHours.length - 1"
						class="locations-modal-locations-list-line back-secondary"
					></span>
				</template>
			</div>

			<div v-if="openAllDay">
				<p>Open All Day</p>
			</div>
		</div>
		<button class="locations-modal-locations-list-btn btn-link-alt colour-primary">
			<span class="btn-container" @click="locationSelected">Select</span>
		</button>
	</div>
</template>

<script>
export default {
	props: {
		location: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			openingHours: [],
			openAllDay: Boolean,
		};
	},
	mounted() {
		let self = this;

		if (self.location.openingHours.length <= 1) {
			return;
		}

		let currentDay = self.location.openingHours.find(day => {
			return day.isCurrentDay;
		});

		if (!currentDay) {
			currentDay = self.location.openingHours[0];
		}

		self.openingHours = currentDay ? currentDay.periods : [];

		self.openAllDay = currentDay.openAllDay;
	},
	methods: {
		locationSelected() {
			let self = this;
			self.$emit('locationSelected', self.location);
		},
	},
};
</script>
