<script>
export default {
  install(Vue) {
    Vue.getLocations = function(
      url,
      successCallback,
      errorCallback,
      finallyCallback
    ) {

      Vue.getDataFromUrl(
        url,
        successCallback,
        errorCallback,
        finallyCallback
      );

    };
  }
};
</script>