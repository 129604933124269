<template>
	<div class="field-wrapper" :class="{ 'input-error': HasErrors }">
		<fieldset class="radio-check-fieldset">
			<label class="checkbox-row radio-check-row">
				{{ LabelText }}
				<slot name="labelText"></slot>
				<input type="checkbox" :name="Name" v-model="Value" @change="change" />
				<span class="checkmark"></span>
			</label>
			<span v-if="HelpText" class="help-text help-text-indented">{{ HelpText }}</span>
			<slot name="helpText"></slot>
			<span class="field-validation" v-show="HasErrors" v-if="Errors.length > 0">
				<span v-for="error in Errors" :key="error">
					{{ error }}
				</span>
			</span>
		</fieldset>
	</div>
</template>
<script>
import Vue from 'vue';
export default {
	name: 'Checkbox',
	props: {
		LabelText: {
			type: String,
			required: false,
		},
		Name: {
			type: String,
			required: true,
		},
		IsCheckedByDefault: {
			type: Boolean,
			default: false,
		},
		IsRequired: {
			type: Boolean,
			default: false,
		},
		RequiredMessage: {
			type: String,
			required: false,
		},
		HelpText: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			FieldType: 'checkbox',
			Value: this.IsCheckedByDefault,
			HasErrors: false,
			Errors: [],
		};
	},
	methods: {
		change() {
			this.validate();
		},
		validate() {
			var validation = Vue.validateFormFieldValue(this.Value, this.IsRequired, this.RequiredMessage);

			this.HasErrors = validation.HasErrors;
			this.Errors = validation.Errors;

			return validation;
		},
		clearErrors() {
			this.HasErrors = false;
			this.Errors = [];
		},
	},
};
</script>
