<template>
	<div v-show="show" class="footer-signup">
		<div class="footer-signup-back back-teal"></div>
		<div class="base-layout width-contained">
			<div class="footer-signup-row">
				<div class="footer-signup-content cw-t-d">
					<div class="footer-signup-form-wrapper back-primary">
						<div class="form-row signup-form-text" v-if="!submitted">
							<h2 class="signup-form-title">{{ title }}</h2>
							<p>{{ description }}</p>
						</div>
						<form class="footer-signup-form signup-form" v-if="!submitted && !hasError">
							<div class="form-row signup-form-inputs">
								<div class="form-col form-col-name">
									<div class="field-wrapper">
										<input type="text" placeholder="Name" class="text" v-model="name" maxlength="500" />
										<span class="field-validation" v-if="hasNameError">
											{{ nameError }}
										</span>
									</div>
								</div>
								<div class="form-col form-col-email">
									<div class="field-wrapper">
										<input type="email" class="text" maxlength="500" v-model="email" placeholder="Email" />
										<span class="field-validation" v-if="hasEmailError">
											{{ emailError }}
										</span>
									</div>
								</div>
								<div class="form-navigation form-col">
									<button class="btn btn-link-alt" @click="submit($event)">
										<span class="btn-container">Sign-up</span>
									</button>
								</div>
							</div>
						</form>
						<div class="form-error-message" v-if="submitted && hasError">
							<div>
								<h2>{{ error }}</h2>
							</div>
						</div>
						<div class="form-thankyou-message" v-if="submitted && showThankYouMesage">
							<div v-html="thankYouMessage"></div>
						</div>
					</div>
				</div>
				<div class="footer-signup-pattern-container">
					<div class="footer-signup-pattern cw-f-y">
						<svg width="1165" height="450" viewBox="0 0 1165 450" fill="none" xmlns="http://www.w3.org/2000/svg">
							<defs>
								<pattern id="signupLines" x="0" y="0" width="1165" height="25" patternUnits="userSpaceOnUse">
									<line x2="1165" stroke-width="20" class="stroke-secondary" />
								</pattern>
								<pattern id="signupDots" x="0" y="-12" width="25" height="25" patternUnits="userSpaceOnUse">
									<circle cx="6" cy="6" r="5" class="fill-primary" />
								</pattern>
							</defs>

							<rect fill="url(#signupLines)" y="0" width="1165" height="64" />
							<rect fill="url(#signupDots)" y="88" width="1165" height="320" />
						</svg>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
export default {
	data() {
		return {
			show: false,
			title: null,
			description: null,
			submissionUrl: null,
			name: null,
			email: null,
			hasNameError: false,
			nameError: null,
			hasEmailError: false,
			emailError: null,
			submitted: false,
			showThankYouMesage: false,
			thankYouMessage: null,
			hasError: false,
			error: null,
		};
	},
	mounted() {
		this.$_loadForm();
	},
	methods: {
		$_loadForm() {
			let self = this;
			let signUpFormProperties = document.getElementById('signUpFormProperties');

			if (signUpFormProperties) {
				let properties = JSON.parse(signUpFormProperties.textContent);
				self.title = properties.title;
				self.description = properties.description;
				self.submissionUrl = properties.submissionUrl;
				self.show = true;
			}
		},
		submit(event) {
			let self = this;

			event.preventDefault();

			if (!self.name || self.name.length <= 0) {
				self.hasNameError = true;
				self.nameError = 'Please enter your name';
			} else {
				self.hasNameError = false;
				self.nameError = null;
			}

			if (!self.email || self.email.length <= 0) {
				self.hasEmailError = true;
				self.emailError = 'Please enter your email address';
			} else {
				self.hasEmailError = false;
				self.emailError = null;
			}

			if (self.hasNameError || self.hasEmailError) {
				return false;
			}

			Vue.submitSignUpForm(
				self.submissionUrl,
				{
					name: self.name,
					emailAddress: self.email,
				},
				self.onSubmitSuccess,
				self.onSubmitError
			);
		},
		onSubmitSuccess(response) {
			let self = this;

			if (response.data.thankYouPageUrl && response.data.thankYouPageUrl.length > 0) {
				self.$_redirectToUrl(response.data.thankYouPageUrl);
				return;
			}

			let msg = response.data.thankYouMessage;

			if (!msg || msg.length <= 0) {
				msg = '<h3>Thank you for signing up</h3>';
			}

			self.thankYouMessage = msg;
			self.showThankYouMesage = true;
			self.submitted = true;
			self.hasError = false;
			self.error = null;
		},
		onSubmitError() {
			self.submitted = true;
			self.hasError = true;
			self.error = 'Something went wrong. Please refresh the page and try again';
		},
		$_redirectToUrl(searchUrl) {
			window.location.href = searchUrl;
		},
	},
};
</script>
