<script>
import axios from "axios";
export default {
  install(Vue) {
    Vue.getDataFromUrl = function (
      url,
      successCallback,
      errorCallback,
      finallyCallback
    ) {
      if (successCallback) {
        if (errorCallback) {
          if (finallyCallback) {
            axios
              .get(url)
              .then(successCallback)
              .catch(errorCallback)
              .finally(finallyCallback);
          } else {
            axios.get(url).then(successCallback).catch(errorCallback);
          }
        } else {
          if (finallyCallback) {
            axios.get(url).then(successCallback).finally(finallyCallback);
          } else {
            axios.get(url).then(successCallback);
          }
        }
      } else if (errorCallback) {
        if (finallyCallback) {
          axios.get(url).catch(errorCallback).finally(finallyCallback);
        } else {
          axios.get(url).catch(errorCallback);
        }
      } else {
        if (finallyCallback) {
          axios.get(url).finally(finallyCallback);
        } else {
          axios.get(url);
        }
      }
    };
  },
};
</script>