<template>
	<div class="tingle-modal tingle-locations-container colour-way cw-db-ma-t tingle-modal--visible">
		<div class="locations-modal-wrapper">
			<div class="tingle-modal-box">
				<div class="tingle-modal-box__content">
					<div id="locationsModalContent" class="locations-modal-content-wrapper">
						<div
							class="locations-modal-close"
							aria-label="Close locations modal"
							role="button"
							aria-pressed="false"
							tabindex="0"
							@click="closeModal"
						></div>
						<div
							class="locations-modal-locations"
							v-bind:style="[selectedLocation !== null ? { display: 'none' } : { display: 'grid' }]"
						>
							<h4>Locations</h4>
							<p
								v-if="holidays && holidays.holidayMessage"
								v-html="holidays.holidayMessage"
								class="locations-holiday"
							></p>
							<locations-list ref="locationsList" @locationSelected="locationSelected" />
						</div>
						<selected-location
							ref="selectedLocation"
							@changeLocation="changeLocation"
							v-bind:style="[selectedLocation === null ? { display: 'none' } : { display: 'grid' }]"
						/>
						<div v-if="hasErrors">
							<ul>
								<li v-for="error in errors" :key="error">{{ error }}</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="tingle-modal-box__footer">
					<div class="locations-modal-dots">
						<div class="locations-modal-dots-container">
							<svg viewBox="0 0 300 1920" xmlns="http://www.w3.org/2000/svg">
								<defs>
									<pattern id="locationModalDots" x="0" y="0" width="61" height="30" patternUnits="userSpaceOnUse">
										<circle cx="6" cy="6" r="6.12293" class="fill-secondary"></circle>
										<circle cx="37" cy="23" r="6.12293" class="fill-secondary"></circle>
									</pattern>
								</defs>
								<rect fill="url(#locationModalDots)" width="300" height="100%"></rect>
							</svg>
						</div>
					</div>
					<div class="locations-modal-lines">
						<div class="locations-modal-lines-container">
							<svg viewBox="0 0 1920 90" xmlns="http://www.w3.org/2000/svg">
								<defs>
									<pattern id="locationsModalLines" x="0" y="0" width="700" height="90" patternUnits="userSpaceOnUse">
										<rect y="0.453491" width="838" height="10" class="fill-tertiary"></rect>
										<rect y="25.4535" width="838" height="10" class="fill-tertiary"></rect>
										<rect y="50.4535" width="838" height="10" class="fill-tertiary"></rect>
										<rect y="75.4535" width="838" height="10" class="fill-tertiary"></rect>
									</pattern>
								</defs>
								<rect fill="url(#locationsModalLines)" width="100%" height="100%"></rect>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import SelectedLocation from '@/components/location-modal/SelectedLocation.vue';
import LocationsList from '@/components/location-modal/LocationsList.vue';

export default {
	data() {
		return {
			initLocationId: null,
			selectedLocation: null,
			locations: [],
			errors: [],
			hasErrors: false,
			initialised: false,
			holidays: {},
		};
	},
	methods: {
		init(locationsUrl, initLocationId) {
			let self = this;

			self.locationsUrl = locationsUrl;

			if (initLocationId) {
				self.initLocationId = initLocationId;
			}

			if (!self.initialised) {
				Vue.getLocations(self.locationsUrl, self.onGetSuccess, self.onGetError);
			}
		},
		onGetSuccess(response) {
			let self = this;

			self.hasErrors = false;
			self.errors.length = 0;

			self.locations.length = 0;

			self.locations = response.data.locationInfo;
			self.holidays = response.data.holidays;
			self.initialised = true;

			self.$refs.locationsList.init(self.locations);

			if (self.initLocationId) {
				let location = self.locations.find(location => location.id === self.initLocationId);

				if (location) {
					self.selectedLocation = location;
					self.$refs.selectedLocation.setLocation(self.selectedLocation);
					self.$emit('locationSelected', self.selectedLocation);
				}
			}
		},
		onGetError(response) {
			console.log('onGetError(response)', response);
			let self = this;
			self.hasErrors = true;
			self.errors.length = 0;
			self.errors.push('Something went wrong. Please refresh the page and try again');
		},
		locationSelected(location) {
			let self = this;

			if (self.selectedLocation && self.selectedLocation.id === location.id) {
				return;
			}

			self.selectedLocation = location;

			if (self.selectedLocation) {
				self.$refs.selectedLocation.setLocation(self.selectedLocation);
			}

			self.$emit('locationSelected', self.selectedLocation);
		},
		changeLocation() {
			let self = this;

			self.selectedLocation = null;
		},
		closeModal() {
			this.$emit('closeModal');
		},
	},
	components: {
		LocationsList,
		SelectedLocation,
	},
};
</script>
