<template>
	<div class="form-row" v-show="Errors.length > 0">
		<div class="form-full-col form-col">
			<div class="field-wrapper input-error">
				<span class="field-validation" v-for="error in Errors" :key="error">
					{{ error }}
					<br />
				</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ErrorPanel',
	data() {
		return {
			Errors: [],
		};
	},
	methods: {
		clearErrors() {
			var self = this;
			self.Errors = [];
		},
		setErrorMessage(error) {
			var self = this;
			self.clearErrors();
			self.Errors.push(error);
		},
		setErrorMessages(errors) {
			var self = this;
			self.clearErrors();
			self.Errors = errors;
		},
	},
};
</script>
