<script>
export default {
  install(Vue) {
    Vue.getPageOfBlogArticles = function(
      url,
      authorId,
      pageNumber,
      pageSize,
      successCallback,
      errorCallback,
      finallyCallback
    ) {
      let fullUrl = `${url}?authorId=${authorId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      Vue.getDataFromUrl(
        fullUrl,
        successCallback,
        errorCallback,
        finallyCallback
      );

    };
  }
};
</script>