<template>
	<li v-show="show" class="nav-button nav-button-double nav-button-arrow">
		<button id="locationModalBtn" class="btn btn-primary back-forest" @click="toggleModal(true)">
			<span class="btn-container">
				<span class="btn-icon las la-school"></span>
				<div class="btn-content">
					<span class="btn-text">{{ selectedLocation ? selectedLocation.title : text }}</span>
					<span class="btn-subtext">{{ selectedLocation ? openingHours : subText }}</span>
				</div>
			</span>
		</button>
		<location-modal
			v-show="showModal"
			ref="locationModal"
			@closeModal="toggleModal(false)"
			@locationSelected="locationSelected"
		/>
	</li>
</template>

<script>
import Vue from 'vue';
import LocationModal from '@/components/location-modal/LocationModal.vue';

export default {
	data() {
		return {
			show: false,
			text: null,
			subText: null,
			showModal: false,
			selectedLocation: null,
			openingHours: null,
		};
	},
	mounted() {
		this.$_loadForm();
	},
	methods: {
		$_loadForm() {
			let self = this;
			let locationsButtonProperties = document.getElementById('locationsButtonProperties');

			if (locationsButtonProperties) {
				let properties = JSON.parse(locationsButtonProperties.textContent);
				self.text = properties.text;
				self.subText = properties.subText;
				self.show = true;

				let locationCookie = self.$cookies.get('yprl-location');

				Vue.nextTick(() => {
					if (self.$refs.locationModal) {
						if (locationCookie) {
							if (typeof locationCookie.selectedLocation === 'number') {
								self.$refs.locationModal.init(properties.locationsUrl, locationCookie.selectedLocation);
							} else {
								let id = locationCookie.selectedLocation.id;
								self.$cookies.set('yprl-location', { selectedLocation: id }, '30d');

								self.$refs.locationModal.init(properties.locationsUrl, id);
							}
						} else {
							self.$refs.locationModal.init(properties.locationsUrl);
						}
					}
				});
			}
		},
		toggleModal(show) {
			let self = this;
			self.showModal = show;

			let body = document.body;

			if (show) {
				body.style.overflow = 'hidden';
			} else {
				body.style.overflow = 'scroll';
			}
		},
		locationSelected(location) {
			let self = this;

			if (self.selectedLocation && self.selectedLocation.id === location.id) {
				return;
			}

			self.selectedLocation = location;

			if (self.selectedLocation.openingHours.length > 0) {
				let currentDay = self.selectedLocation.openingHours.find(day => day.isCurrentDay);

				if (currentDay) {
					if (currentDay.openAllDay) {
						self.openingHours = 'Open all Day!';
					} else {
						let currentPeriod = currentDay.periods.find(period => period.isCurrentPeriod);

						if (currentPeriod) {
							self.openingHours = `${currentPeriod.openingTime} | ${currentPeriod.closingTime}`;
						} else {
							self.openingHours = 'Closed Today';
						}
					}
				} else {
					self.openingHours = 'Closed Today';
				}
			} else {
				self.openingHours = 'Closed Today';
			}

			let locationCookie = self.$cookies.get('yprl-location');

			if (!locationCookie) {
				locationCookie = {
					selectedLocation: self.selectedLocation.id,
				};
			} else {
				locationCookie.selectedLocation = self.selectedLocation.id;
			}

			self.$cookies.set('yprl-location', locationCookie, '30d');
		},
	},
	components: {
		LocationModal,
	},
};
</script>
