<template>
	<div class="field-wrapper" :class="[AdditionalCssClasses, { 'input-error': HasErrors }]">
		<FieldLabel :LabelText="LabelText" :IsRequired="IsRequired" />
		<span v-if="HelpText" class="help-text">{{ HelpText }}</span>
		<input
			class="text"
			:type="Type"
			:name="Name"
			v-model="Value"
			:placeholder="PlaceholderText"
			:maxlength="MaxLength"
			@blur="change"
		/>
		<span class="field-validation" v-show="HasErrors" v-if="Errors.length > 0">
			<span v-for="error in Errors" :key="error">
				{{ error }}
			</span>
		</span>
	</div>
</template>
<script>
import Vue from 'vue';
import FieldLabel from '@/components/form-fields/FieldLabel.vue';

export default {
	name: 'TextBox',
	props: {
		LabelText: {
			type: String,
			required: true,
		},
		Type: {
			type: String,
			required: true,
		},
		Name: {
			type: String,
			required: true,
		},
		PlaceholderText: {
			type: String,
			required: false,
		},
		IsRequired: {
			type: Boolean,
			default: false,
		},
		RunValidationIfFieldHasValue: {
			type: Boolean,
			default: false,
		},
		RequiredMessage: {
			type: String,
			required: false,
		},
		ValidationType: {
			type: String,
			required: false,
		},
		ValidationMessage: {
			type: String,
			required: false,
		},
		CustomValidation: {
			type: String,
			required: false,
		},
		AdditionalCssClasses: {
			type: String,
			required: false,
		},
		MaxLength: {
			type: Number,
			required: false,
		},
		InitialValue: {
			type: String,
			required: false,
		},
		HelpText: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			FieldType: 'textBox',
			Value: null,
			HasErrors: false,
			Errors: [],
		};
	},
	mounted() {
		var self = this;
		self.Value = self.InitialValue;
	},
	methods: {
		change() {
			if (this.Value === null || this.Value.length <= 0) {
				return;
			}
			this.validate();
		},
		validate() {
			var isRequired =
				this.IsRequired || (this.RunValidationIfFieldHasValue && this.Value !== null && this.Value.length > 0);

			var validation = Vue.validateFormTextFieldValue(
				this.Value,
				isRequired,
				this.RequiredMessage,
				this.ValidationType,
				this.ValidationMessage,
				this.CustomValidation
			);

			this.HasErrors = validation.HasErrors;
			this.Errors = validation.Errors;

			if (!validation.HasErrors) {
				this.$emit('onValid', this.Value);
			}

			return validation;
		},
		clearErrors() {
			this.HasErrors = false;
			this.Errors = [];
		},
	},
	components: {
		FieldLabel,
	},
};
</script>
