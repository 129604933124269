<template>
	<div class="locations-modal-location">
		<div class="locations-modal-location-header">
			<span class="locations-modal-location-icon las la-school colour-primary"></span>
			<div class="locations-modal-location-text">
				<p class="locations-modal-location-pretitle">Prefered Library Branch</p>
				<p class="locations-modal-location-title">{{ title }}</p>
			</div>
		</div>
		<p
			v-if="holidaysData && holidaysData.holidayMessage"
			v-html="holidaysData.holidayMessage"
			class="locations-holiday"
		></p>
		<div class="locations-modal-location-content">
			<div class="locations-modal-location-opening">
				<div class="locations-modal-location-opening-list">
					<div
						v-for="day in openingHours"
						:key="day.dayOfWeek"
						class="locations-modal-location-opening-item back-primary"
						:class="{ active: day.isCurrentDay && !isHoliday, 'not-active': !day.isCurrentDay || isHoliday }"
					>
						<div class="locations-modal-location-opening-day">
							<p>{{ day.isCurrentDay && !isHoliday ? 'Today' : day.dayOfWeek }}</p>
						</div>

						<div class="locations-modal-location-opening-times" v-if="day.periods.length > 0 && !day.openAllDay">
							<template v-for="(period, index) in day.periods">
								<p :key="period.openingTime">{{ period.openingTime }} – {{ period.closingTime }}</p>
								<span
									:key="period.closingTime"
									v-if="index < day.periods.length - 1"
									class="locations-modal-location-opening-line back-secondary"
								></span>
							</template>
						</div>

						<div class="locations-modal-location-opening-times" v-if="day.periods.length == 0 && !day.openAllDay">
							<p>Closed</p>
						</div>

						<div class="locations-modal-location-opening-times" v-if="day.openAllDay">
							<p>Open all day</p>
						</div>
					</div>
				</div>
			</div>

			<div class="locations-modal-location-links" v-if="phoneNumber && phoneNumber.length > 0">
				<div class="locations-modal-location-link">
					<a class="animate-phone" :href="'tel:' + phoneNumber">
						<span class="btn-icon las la-phone"></span>
						<p class="animate-text">{{ phoneNumber }}</p>
					</a>
				</div>
				<div class="locations-modal-location-link" v-if="address && address.length > 0">
					<a class="animate-address" :href="mapLinkUrl ? mapLinkUrl : ''" :target="mapLinkTarget">
						<span class="btn-icon las la-map-marker"></span>
						<p class="animate-text">{{ address }}</p>
					</a>
				</div>
			</div>
		</div>

		<div class="locations-modal-location-change">
			<button class="btn btn-primary" @click="changeLocationClicked">
				<span class="btn-container">
					<span class="btn-icon las la-edit"></span>
					<span class="btn-text">Change</span>
				</span>
			</button>
		</div>

		<div class="locations-modal-location-info-buttons" v-if="mapLinkUrl && mapLinkUrl.length > 0">
			<a class="btn btn-primary" :href="mapLinkUrl" :target="mapLinkTarget">
				<span class="btn-container">
					<span class="btn-icon las la-map-marked-alt"></span>
					<span class="btn-text">Get Directions</span>
				</span>
			</a>
			<a class="btn btn-primary" :href="url">
				<span class="btn-container">
					<span class="btn-icon las la-info-circle"></span>
					<span class="btn-text">More Info</span>
				</span>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			title: null,
			phoneNumber: null,
			phoneNumberTelLink: null,
			address: null,
			openingHours: [],
			url: null,
			mapLinkUrl: null,
			mapLinkTarget: null,
			holidaysData: {},
			isHoliday: Boolean,
		};
	},
	methods: {
		setLocation(location) {
			let self = this;

			if (!location) {
				return;
			}

			self.title = location.title;
			self.phoneNumber = location.phoneNumber;
			self.phoneNumberTelLink = location.phoneNumberTelLink;
			self.address = location.address;
			self.url = location.url;
			self.mapLinkUrl = location.mapLinkUrl;
			self.mapLinkTarget = location.mapLinkTarget;
			self.holidaysData = location.holidays;
			self.isHoliday = location.holidays.isHoliday;

			self.openingHours = location.openingHours.map(day => {
				return {
					dayOfWeek: day.dayOfWeek,
					isCurrentDay: day.isCurrentDay,
					openAllDay: day.openAllDay,
					periods: day.periods.map(period => {
						return {
							isCurrentPeriod: period.isCurrentPeriod,
							openingTime: period.openingTime,
							closingTime: period.closingTime,
						};
					}),
				};
			});
		},
		changeLocationClicked() {
			this.$emit('changeLocation');
		},
	},
};
</script>
