<template>
	<li v-show="show" class="nav-button header-desktop-search">
		<button id="searchModalBtn" class="btn btn-primary back-deep-blue" @click="toggleModal(true)">
			<span class="btn-container">
				<span class="btn-icon las la-search"></span>
				<span class="btn-text">{{ text }}</span>
			</span>
		</button>
		<SearchModal ref="searchModal" v-show="showModal" @closeModal="toggleModal(false)" />
	</li>
</template>

<script>
import SearchModal from '@/components/search-modal/SearchModal.vue';

export default {
	data() {
		return {
			show: false,
			text: null,
			searchTerm: null,
			searchTermMissing: false,
			showModal: false,
		};
	},
	mounted() {
		this.$_loadForm();
	},
	methods: {
		$_loadForm() {
			let self = this;
			let searchButtonProperties = document.getElementById('searchButtonProperties');

			if (searchButtonProperties) {
				let properties = JSON.parse(searchButtonProperties.textContent);
				self.text = properties.text;
				self.$refs.searchModal.init(properties);
				self.show = true;
			}
		},
		toggleModal(show) {
			let self = this;
			let body = document.body;
			let headerBack = document.getElementById('headerBack');

			if (show) {
				body.style.overflow = 'hidden';
				headerBack.style.maxHeight = '100vh';
				self.showModal = show;
			} else {
				headerBack.style.maxHeight = '97px';
				setTimeout(function() {
					body.style.overflow = 'scroll';
					self.showModal = show;
				}, 500);
			}
		},
	},
	components: {
		SearchModal,
	},
};
</script>
