<template>
	<button v-show="show" class="system-alerts-close" aria-label="Close Alert" @click="closeAlerts">
		<span class="las la-times colour-secondary"></span>
	</button>
</template>

<script>
export default {
	data() {
		return {
			show: false,
		};
	},
	mounted() {
		this.$_loadForm();
	},
	methods: {
		$_loadForm() {
			let self = this;
			let alertsCloseButtonProperties = document.getElementById('alertsCloseButtonProperties');

			if (alertsCloseButtonProperties) {
				let properties = JSON.parse(alertsCloseButtonProperties.textContent);
				self.show = properties.wireUpAlertsButton;
			}
		},
		closeAlerts() {
			let self = this;
			let alertsCookie = self.$cookies.get('yprl-system-alerts');

			if (alertsCookie) {
				alertsCookie.closeAlerts = true;
			} else {
				alertsCookie = {
					closeAlerts: true,
				};
			}

			self.$cookies.set('yprl-system-alerts', alertsCookie, '24h');

			let alerts = document.getElementById('yprl-system-alerts');

			if (alerts) {
				alerts.style.display = 'none';
			}
		},
	},
};
</script>
