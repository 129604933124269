<script>
import axios from 'axios';
export default {
	install: function(Vue) {
		var baseUrl = '/umbraco/api/';
		var baseAddressApiUrl = baseUrl + 'AddressLookup/';
		var addressAutocompleteUrl = baseAddressApiUrl + 'Search';
		var addressGetUrl = baseAddressApiUrl + 'Get';

		Vue.addressAutocompleteDataSource = addressAutocompleteUrl + '?address=';

		var self = this;
		self.addressGetUrl = addressGetUrl;

		Vue.getAddress = function(id, successCallback, errorCallback, finallyCallback) {
			var url = `${self.addressGetUrl}?address=${id}`;

			if (successCallback) {
				if (errorCallback) {
					if (finallyCallback) {
						axios
							.get(url)
							.then(successCallback)
							.catch(errorCallback)
							.finally(finallyCallback);
					} else {
						axios
							.get(url)
							.then(successCallback)
							.catch(errorCallback);
					}
				} else {
					if (finallyCallback) {
						axios
							.get(url)
							.then(successCallback)
							.finally(finallyCallback);
					} else {
						axios.get(url).then(successCallback);
					}
				}
			} else if (errorCallback) {
				if (finallyCallback) {
					axios
						.get(url)
						.catch(errorCallback)
						.finally(finallyCallback);
				} else {
					axios.get(url).catch(errorCallback);
				}
			} else {
				if (finallyCallback) {
					axios.get(url).finally(finallyCallback);
				} else {
					axios.get(url);
				}
			}
		};
	},
};
</script>
