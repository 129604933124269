<template>
	<div id="PersonalDetails" ref="PersonalDetails" :class="{ 'd-none': IsHidden }">
		<div class="form">
			<p>
				Please fill out this form to become a Member of Yarra Plenty Regional Library, fields marked with a
				<span class="required">*</span>
				are mandatory
			</p>
			<ErrorPanel ref="errorPanel" />
			<div class="form-wrapper">
				<div class="form-row">
					<div class="form-full-col form-col">
						<TextBox
							ref="firstNameFormField"
							LabelText="First Name(s)"
							Type="text"
							Name="firstName"
							PlaceholderText="First Name(s)"
							:IsRequired="true"
							RequiredMessage=""
							ValidationType=""
							ValidationMessage=""
							CustomValidation=""
							AdditionalCssClasses=""
							InitialValue=""
						/>
						<TextBox
							ref="lastNameFormField"
							LabelText="Last Name"
							Type="text"
							Name="lastName"
							PlaceholderText="Last Name"
							:IsRequired="true"
							RequiredMessage=""
							ValidationType=""
							ValidationMessage=""
							CustomValidation=""
							AdditionalCssClasses=""
							InitialValue=""
						/>
						<DatePicker
							ref="dateOfBirthFormField"
							LabelText="Date of Birth"
							Name="dateOfBirth"
							PlaceholderText="Date of Birth"
							:IsRequired="true"
							RequiredMessage=""
							ValidationType=""
							ValidationMessage=""
							CustomValidation=""
							AdditionalCssClasses=""
							@change="dateOfBirthChange"
						/>
						<Dropdown
							ref="genderFormField"
							LabelText="Gender"
							Name="Gender"
							PlaceholderText="Please select"
							:Options="Genders"
							:IsRequired="false"
							@change="genderChanged"
							AdditionalCssClasses=""
						/>
						<div v-show="IsSelfDescribedGender">
							<TextBox
								ref="selfDescribedGenderFormField"
								LabelText="My Gender is"
								Type="text"
								Name="selfDescribedGender"
								PlaceholderText="My Gender is"
								:IsRequired="false"
								RequiredMessage=""
								ValidationType=""
								ValidationMessage=""
								CustomValidation=""
								AdditionalCssClasses=""
								InitialValue=""
								:MaxLength="50"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-col form-navigation">
					<button type="submit" class="btn btn-primary back-secondary" :disabled="Submitting" @click="validate">
						<span v-if="!Submitting">
							Next
							<span class="las la-sign-in-alt"></span>
						</span>
						<span v-if="Submitting" class="btn-container">
							<span class="btn-text">Submitting</span>
							<span class="btn-icon btn-icon-right las la-spinner la-spin"></span>
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<style type="text/css">
.la-spin {
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
<script>
import Vue from 'vue';
import gendersJson from '@/assets/library-signup-form-genders.json';
import ErrorPanel from '@/components/library-sign-up-form/ErrorPanel.vue';
import TextBox from '@/components/form-fields/TextBox.vue';
import Dropdown from '@/components/form-fields/Dropdown.vue';
import DatePicker from '@/components/form-fields/DatePicker.vue';

export default {
	name: 'Step01PersonalDetails',
	components: {
		ErrorPanel,
		TextBox,
		Dropdown,
		DatePicker,
	},
	data() {
		return {
			IsHidden: false,

			Genders: gendersJson,
			IsSelfDescribedGender: false,

			FirstName: null,
			LastName: null,
			DateOfBirth: null,
			Gender: null,
			SelfDescribedGender: null,

			ParentGuardianRequired: false,

			HasErrors: false,
			Errors: [],
			Submitting: false,
		};
	},
	methods: {
		init(debug) {
			var self = this;
			if (debug.enabled) {
				self.$refs.firstNameFormField.Value = debug.firstName;
				self.$refs.lastNameFormField.Value = debug.lastName;
				self.$refs.dateOfBirthFormField.Value = debug.dateOfBirth;
			}
		},
		validate() {
			var self = this;

			self.HasErrors = false;
			self.Errors = [];
			self.Submitting = false;

			self.dateOfBirthChange(self.$refs.dateOfBirthFormField.Value);

			var formFields = [
				self.$refs.firstNameFormField,
				self.$refs.lastNameFormField,
				self.$refs.dateOfBirthFormField,
				self.$refs.genderFormField,
			];

			if (self.IsSelfDescribedGender) {
				formFields.push(self.$refs.selfDescribedGenderFormField);
			}

			for (var i = 0; i < formFields.length; i++) {
				var validate = formFields[i].validate();
				if (validate.HasErrors) {
					self.HasErrors = true;
				}
			}

			if (self.HasErrors) {
				self.Errors.unshift('Please ensure all fields are correct');
				self.$refs.errorPanel.setErrorMessages(self.Errors);
				self.$refs.PersonalDetails.scrollIntoView({ behavior: 'smooth' });
			} else {
				self.searchForExistingBorrower();
			}
		},
		dateOfBirthChange(dob) {
			var self = this;

			var ageDifMs = Date.now() - dob;
			var ageDate = new Date(ageDifMs);
			var ageInYears = Math.abs(ageDate.getUTCFullYear() - 1970);

			self.ParentGuardianRequired = ageInYears < 18;
		},
		genderChanged(gender) {
			var self = this;

			var selectedGender = self.Genders.find(x => x.Value == gender);

			var isSelfDescribed = self.Genders.indexOf(selectedGender) === self.Genders.length - 1;

			self.IsSelfDescribedGender = isSelfDescribed;

			if (self.IsSelfDescribedGender == false) {
				self.SelfDescribedGender = null;
				self.$refs.selfDescribedGenderFormField.Value = null;
			}
		},
		searchForExistingBorrower() {
			var self = this;

			var request = {
				firstName: self.$refs.firstNameFormField.Value,
				lastName: self.$refs.lastNameFormField.Value,
				dateOfBirth: self.getFormattedDateOfBirth(),
			};

			self.Submitting = true;

			Vue.searchForExistingBorrower(
				request,
				function(response) {
					console.log('this is searchForExistingBorrower response:');
					console.log(response);
					self.Submitting = false;
					if (response.data) {
						self.HasErrors = true;
						self.Errors.push('Based on the details entered, the borrower already exists.');
						self.$refs.errorPanel.setErrorMessages(self.Errors);
						self.$refs.PersonalDetails.scrollIntoView({ behavior: 'smooth' });
					} else {
						self.$refs.errorPanel.clearErrors();
						self.submit();
					}
				},
				function(response) {
					//the client has specified that they don't want the form to stop here if there is an error in the api call
					self.Submitting = false;
					console.log('this is searchForExistingBorrower error response:');
					console.log(response);
				}
			);
		},
		getFormattedDateOfBirth() {
			var self = this;
			return `${self.$refs.dateOfBirthFormField.Value.getFullYear()}-${self.$refs.dateOfBirthFormField.Value.getMonth() +
				1}-${self.$refs.dateOfBirthFormField.Value.getDate()}`;
		},
		submit() {
			var self = this;

			if (self.HasErrors) {
				self.$refs.PersonalDetails.scrollIntoView({ behavior: 'smooth' });
				return;
			}

			self.FirstName = self.$refs.firstNameFormField.Value;
			self.LastName = self.$refs.lastNameFormField.Value;
			self.DateOfBirth = self.getFormattedDateOfBirth();
			self.Gender = self.$refs.genderFormField.Value;
			self.SelfDescribedGender = self.$refs.selfDescribedGenderFormField.Value;

			self.debug();

			self.$emit('next', {
				FirstName: self.FirstName,
				LastName: self.LastName,
				DateOfBirth: self.DateOfBirth,
				Gender: self.Gender,
				SelfDescribedGender: self.SelfDescribedGender,
				ParentGuardianRequired: self.ParentGuardianRequired,
			});
		},
		hide() {
			this.IsHidden = true;
		},
		show() {
			this.IsHidden = false;
		},
		debug() {
			var self = this;

			console.group('Step01PersonalDetails debug()');
			console.log('FirstName: ' + self.FirstName);
			console.log('LastName: ' + self.LastName);
			console.log('DateOfBirth: ' + self.DateOfBirth);
			console.log('Gender: ' + self.Gender);
			console.log('SelfDescribedGender: ' + self.SelfDescribedGender);
			console.log('ParentGuardianRequired: ' + self.ParentGuardianRequired);

			console.log('HasErrors: ' + self.HasErrors);

			if (self.HasErrors) {
				console.log(self.Errors);
			}

			console.groupEnd();
		},
	},
};
</script>
