<template>
	<section class="search-hero">
		<h1 class="colour-tertiary">Search</h1>
		<h4 v-if="initialSearchTerm" class="search-hero-term">
			Showing results for:
			<span class="colour-tertiary">{{ initialSearchTerm }}</span>
		</h4>
		<div class="search-hero-form-section">
			<div class="search-hero-form">
				<div class="search-hero-input">
					<input
						type="text"
						placeholder="Search"
						v-model="searchTerm"
						v-on:keyup.enter="searchCatalogue($event)"
						autocomplete="off"
						:class="{ searchTermMissing: 'error' }"
					/>
					<button class="search-hero-input-button colour-secondary" aria-label="Search" @click="searchWebsite"></button>
				</div>
				<div class="search-hero-buttons">
					<button class="btn btn-primary back-tertiary" @click="searchCatalogue">
						<span class="btn-container">
							<span class="btn-icon las la-book-open"></span>
							<span class="btn-text">Search the Catalogue</span>
						</span>
					</button>
					<button class="btn btn-primary back-tertiary" @click="searchWebsite">
						<span class="btn-container">
							<span class="btn-icon las la-desktop"></span>
							<span class="btn-text">Search the Website</span>
						</span>
					</button>
					<button class="btn btn-primary back-tertiary" @click="searchEvents">
						<span class="btn-container">
							<span class="btn-icon las la-calendar"></span>
							<span class="btn-text">Search Events</span>
						</span>
					</button>
				</div>
			</div>
			<a class="btn-link-alt colour-tertiary search-hero-link" :href="advancedSearchPageUrl" target="_blank">
				<span class="btn-container">Advanced Search</span>
			</a>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		initialSearchTerm: {
			type: String,
			required: false,
		},
		searchWebsiteUrl: {
			type: String,
			required: true,
		},
		searchCatalogueUrl: {
			type: String,
			required: true,
		},
		searchEventsUrl: {
			type: String,
			required: true,
		},
		advancedSearchPageUrl: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			searchTerm: this.initialSearchTerm,
			searchTermMissing: false,
		};
	},
	methods: {
		searchWebsite() {
			if (!this.searchTerm || this.searchTerm.length <= 0) {
				this.searchTermMissing = true;
				return;
			}

			this.searchTermMissing = false;

			let url = this.searchWebsiteUrl + '?t=' + this.searchTerm;
			this.$_redirectToUrl(url, false);
		},
		searchCatalogue(event) {
			if (event) {
				event.preventDefault();
			}

			let self = this;
			let term = self.searchTerm ? self.searchTerm.replace(' ', '+') : '';
			let url = `${self.searchCatalogueUrl}?ENTRY=${term}&ENTRY_NAME=BS&ENTRY_TYPE=K&SORTS=SQL_REL_BIB&GQ=${term}&ISGLB=1&NRECS=20`;
			self.$_redirectToUrl(url, true);
		},
		searchEvents() {
			let self = this;
			let term = self.searchTerm ? self.searchTerm.replace(' ', '+') : '';
			let url = `${self.searchEventsUrl}?keywords=${term}`;
			self.$_redirectToUrl(url, false);
		},
		$_redirectToUrl(searchUrl, newTab) {
			if (newTab) {
				window.open(searchUrl, '_blank');
				return;
			}

			window.location.href = searchUrl;
		},
	},
};
</script>
